import React, { useContext } from "react";
import star from "../../../img/star.svg";
import save from "../../../img/save.svg";
import filledWatchLaterIcon from "../../../img/BookmarkSimple.svg";

import { makeReqest } from "../../../axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../../context/authContext";

const SliderCardInfo = ({ serie, infoStyle }) => {
  const queryClient = useQueryClient();
  const currentUser = useContext(AuthContext);
  const addToWatchList = useMutation({
    mutationFn: (id) => {
      return makeReqest.post(`wishlist/series/${serie.serie_id}`);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["home-data"]);
    },
  });

  return (
    <>
      <div className="SliderCardInfo" style={infoStyle}>
        <h4>
          {serie.serie_title}
          {/* / {serie.serie_duration} */}
        </h4>
        <div className="CardMeta">
          <div className="CardRating">
            <img className="star" src={star} alt="star" />
            <h3>{serie?.avg_rating}</h3>
            {/* {console.log(serie.is_wishlist)} */}

            {currentUser.currentUser?.body.user.user_status && (
              <img
                className="save"
                src={serie?.is_wishlist ? filledWatchLaterIcon : save}
                alt="save"
                onClick={(e) => {
                  e.stopPropagation();
                  addToWatchList.mutate(serie.serie_id);
                }}
              />
            )}
            {/* {currentUser.currentUser?.body.user.user_status && (
            <img className={classes.bookmark} src={bookmark} alt="bookmark" />
          )} */}
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
            <circle cx="1.5" cy="2" r="1.5" fill="white" />
          </svg>
          <div className="CardGenre">{serie.genre_title}</div>
          <svg xmlns="http://www.w3.org/2000/svg" width="3" height="4" viewBox="0 0 3 4" fill="none">
            <circle cx="1.5" cy="2" r="1.5" fill="white" />
          </svg>
          <div className="CardYear">{serie.serie_year}</div>
        </div>
        <div className="CardDesc">{serie.serie_description}</div>
      </div>
    </>
  );
};

export default SliderCardInfo;
