import React, {useContext} from 'react';
import classes from './PaymentPlan.module.css';
import paypal from './../../img/paypal.svg';
import CreditCard from './../../img/CreditCard.svg';
import {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../axios';
import {AuthContext} from '../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import Spinner from '../spinner/Spinner';
import Stripe from 'stripe';

const PaymentPlan = () => {
	const [pkgId, setPkgId] = useState(null);
	const [cardType, setCardType] = useState('');
	const token = Cookies.get('token');
	const [activeClass, setActiveClass] = useState('');

	const {data, isLoading} = useQuery({
		queryKey: ['packages'],
		queryFn: () => {
			return makeReqest.get('packages');
		},
	});
	const {currentUser} = useContext(AuthContext);

	const changePaymentMethod = useMutation({
		mutationFn: () => {
			return makeReqest.post(`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/payment_method`, {
				payment_method: {
					type: cardType,
					card: {
						// number: cardNumber,
						// exp_month: expireMonth,
						// exp_year: expireYear,
						// cvc,
					},
				},
			});
		},
	});

	const stripePaymentMethod = useMutation({
		mutationFn: () => {
			return makeReqest.post(`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/${pkgId}/stripe/create-checkout-session`, {});
		},
		onSuccess: async (respnse) => {
			window.location.href = respnse.data.session_url;
		},
	});

	const paymentMutation = useMutation({
		mutationFn: () => {
			return makeReqest.post(`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/${pkgId}`, {
				payment_method: {
					type: cardType,
					//   card: {
					//     number: cardNumber,
					//     exp_month: expireMonth,
					//     exp_year: expireYear,
					//     cvc,
					//   },
				},
			});
		},
		onSuccess: async (data) => {

		},
		onError: (error) => {
			toast.error(error.response.data.message || error.response.data.error, {
				position: 'top-center',
				autoClose: false,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
		},
	});

	const submitHandler = (e) => {
		e.preventDefault();
		// changePaymentMethod.mutate();
		if (cardType === 'Paypal') handlePaypalPayment();
		if (cardType === 'Stripe') handleStripePayment();
	};

	// const config = {
	//   headers: { Authorization: `Bearer ${token}`, mode: "cors" },
	// };
	// const { data:paypalData } = useQuery({
	//   queryKey: ["paypal",pkgId],
	//   queryFn: () => {
	//     return axios.get(`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`,config)
	//   },
	//   enabled:!!pkgId
	// });
	// if (pkgId) {
	//   fetch(
	//     `${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`,
	//     config
	//   ).then((res) => console.log(res));
	// }
	// console.log(paypalData);
	function handlePaypalPayment() {
		const url = `${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser.body.user.user_id}/${pkgId}/pay`;
		const headers = new Headers();
		headers.append('Authorization', `Bearer ${token}`);
		window.location.href = url;

		// Open the Google authentication link in the current tab
		// const authWindow = window.open(googleAuthUrl, '_blank');
		// window.addEventListener('message', (event) => {
		//   if (event.origin === window.location.origin) {
		// Save the response in local storage
		// localStorage.setItem('user', JSON.stringify(event.data));

		// Close the authentication window
		//   authWindow.close();

		//   // Move back to the current React application
		//   window.location.reload();
		// }
		// });
	}

	function handleStripePayment() {
		stripePaymentMethod.mutate();
	}

	return (
		<div className={classes.paymentPlanTop}>
			<h1>Please Choose A Plan</h1>
			<p className={classes.paraTop}>You can update your plan or change to another one</p>
			<div className={classes.row}>
				{isLoading ? (
					<Spinner/>
				) : (
					data?.data.body.packages.map((pkg) => (
						<div className={`${classes.bronze} `} onClick={() => setActiveClass(pkg.title)} style={{border: activeClass === pkg.title ? '1px solid rgb(19, 157, 224, 0.45)' : '',}}>
				              <span className={classes.inner} onClick={() => setPkgId(pkg.id)}>
				                <h2>{pkg.title}</h2>
				                <p>
				                  ${pkg.amount}{' '}
					                <span>
				                    /{pkg.duration} {pkg.type === 'days' ? 'days' : pkg.type === 'monthly' && pkg.duration > 1 ? 'months' : pkg.type === 'monthly' && pkg.duration == 1 ? 'month' : pkg.type === 'yearly' ? 'year' : ''}
				                  </span>
				                </p>
				              </span>
						</div>
					))
				)}
			</div>
			<div className={classes.paymentMethod}>
				<h1>Select payment method</h1>
				<div className={classes.rowCard}>
					<button onClick={() => {setCardType('Paypal');}} style={{border: cardType === 'Paypal' ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}>{' '}
						<span>
			              <img src={paypal} alt="paypal"/> Paypal
			            </span>
					</button>

					<button onClick={() => {setCardType('Stripe');}} style={{border: cardType === 'Stripe' ? '1px solid rgb(19, 157, 224, 0.45)' : ''}}>
			            <span>
			              {' '}
				            <img src={CreditCard} alt="CreditCard"/>
			              Stripe
			            </span>
					</button>
				</div>

				<button className={classes.payBtn} onClick={submitHandler} type="submit">
					Pay & Enter
				</button>
			</div>
			<ToastContainer limit={1}/>
		</div>
	);
};
export default PaymentPlan;
