import React, { useContext } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Next from "./../../../StaticPic/Next.png";
import play from "./../../../img/Play.svg";
import classes from "./LatestEpisode.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { AuthContext } from "../../../context/authContext";
// let slidesToShow = 5;
// const PreviousBtn = (props) => {
//   const { className, onClick, currentSlide } = props;
//   return (
//     <>
//       {currentSlide !== 0 && (
//         <div className={className} onClick={onClick} style={{ left: "-60px" }}>
//           {/* <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} /> */}
//           <img className={classes.carouselPrev} src={Next} alt="prev" />
//         </div>
//       )}
//     </>
//   );
// };
// const NextBtn = (props) => {
//   const { className, onClick, slideCount, currentSlide } = props;
//   return (
//     <>
//       {currentSlide !== slideCount - slidesToShow && (
//         <div className={className} onClick={onClick}>
//           {/* <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} /> */}
//           <img className={classes.carouselNext} src={Next} alt="next" />
//         </div>
//       )}
//     </>
//   );
// };

// const carouselProperties = {
//   prevArrow: <PreviousBtn />,
//   nextArrow: <NextBtn />,
//   slidesToShow: slidesToShow,
//   slidesToScroll: 2,
//   infinite: false,
//   // slidesToScroll={3}
//   responsive: [
//     {
//       breakpoint: 700,
//       settings: {
//         slidesToShow: 1.1,
//         centerMode: false,
//       },
//     },
//     {
//       breakpoint: 769,
//       settings: {
//         slidesToShow: 2,
//         centerMode: false,
//       },
//     },
//     {
//       breakpoint: 1025,
//       settings: {
//         slidesToShow: 2,
//         centerMode: false,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 2050,
//       settings: {
//         slidesToShow: 3,
//         centerMode: false,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

const SeriesEpisodes = ({ seasons, canPlay }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const displayPer = 6;
  const [displayCount, setDisplayCount] = useState(displayPer);
  const [selectedTab, setSelectedTab] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  useEffect(() => {
    if (seasons && seasons[0]?.episodes) setTotalRecords(seasons[selectedTab]?.episodes?.length || seasons[0]?.episodes.length);
  }, [seasons, selectedTab]);

  const tabChanged = (indx) => {
    setSelectedTab(indx);
    setDisplayCount(displayPer);
  };

  const showMore = () => {
    if (displayCount < totalRecords) {
      const newCount = displayCount + displayPer;
      setDisplayCount(newCount);
    }
  };

  // if (width <= 426) {
  //   slidesToShow = 1;
  // } else if (width > 426 && width <= 769) {
  //   slidesToShow = 2;
  // } else if (width > 769 && width <= 1025) {
  //   slidesToShow = 2;
  // } else if (width > 1025 && width <= 1920) {
  //   slidesToShow = 3;
  // } else {
  //   slidesToShow = 3;
  // }

  //   const { data, isSuccess } = useQuery({
  //     queryKey: ["home-data"],
  //     queryFn: () => {
  //       return axios.get(`${process.env.REACT_APP_BASE_URL}/home`);
  //     },
  //   });
  return (
    <Tabs onSelect={(selectedIndex) => tabChanged(selectedIndex)}>
      <div className={classes.carousel} style={{ textAlign: "center" }}>
        <h2 style={{ fontSize: "24px" }}>
          <TabList>
            {seasons?.map((season) => (
              <Tab>
                <span style={{ marginLeft: "10px" }}>{season.title}</span>
              </Tab>
            ))}
          </TabList>
        </h2>

        {seasons?.map((season) => (
          <TabPanel>
            {/* <Slider {...carouselProperties}>
              {season?.episodes.map((episode) => (
                <Card episodes={episode} canPlay={canPlay} key={episode.episode_id} />
              ))}
            </Slider> */}
            <div className={classes.episodesGrid}>
              {season?.episodes.slice(0, displayCount).map((episode) => (
                <Card episodes={episode} canPlay={canPlay} key={episode.episode_id} />
              ))}
            </div>
            {displayCount < totalRecords && (
              <div className={classes.showMoreContainer}>
                <div className={classes.showMore} onClick={showMore}>
                  Show More
                </div>
                <div className={classes.displayInfo}>
                  Showing {displayCount} of {totalRecords} episodes
                </div>
              </div>
            )}
          </TabPanel>
        ))}
      </div>
    </Tabs>
  );
};

const Card = ({ episodes, canPlay }) => {
  // console.log();
  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div
      className={classes.card}
      onClick={() => {
        if (!currentUser.currentUser) {
          navigate("/signin");
        } else canPlay ? navigate(`/watchEpisodes/${episodes?.episode_id}`) : navigate("/choosepayment");
      }}
    >
      <img className={classes.playSeriesEp} src={play} alt="play" />
      <div className={classes.posterSeries}>
        <img
          className={classes.multiImage}
          src={`${process.env.REACT_APP_BASE_URL}/images/${episodes.episode_image}`}
          alt=""
          style={{
            width: "100%",
            borderRadius: "20px",
          }}
        />
      </div>

      <h2>{episodes.episode_title}</h2>
      {/* <p>Season 1 Episode 3</p> */}
    </div>
  );
};

export default SeriesEpisodes;
