import React, { useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Next from "./../../../StaticPic/Next.png";
import star from "./../../../img/star.svg";
import classes from "./carousel.module.css";
// import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router";
import { AuthContext } from "../../../context/authContext";
import Spinner from "../../spinner/Spinner";
let slidesToShow = 5;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      {/* {currentSlide !== 0 && ( */}
      <div className={className} onClick={onClick} style={{ left: "70px", zIndex: "90", width: "50px", height: "50px" }}>
        {/* <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} /> */}
        <img className={classes.carouselPrev} src={Next} alt="next" />
      </div>
      {/* )} */}
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      {/* {currentSlide !== slideCount - slidesToShow && ( */}
      <div className={className} onClick={onClick} style={{ right: "110px" }}>
        {/* <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} /> */}
        <img className={classes.carouselNext} src={Next} alt="next" />
      </div>
      {/* )} */}
    </>
  );
};

const carouselProperties = {
  prevArrow: <PreviousBtn />,
  nextArrow: <NextBtn />,
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  centerMode: true,
  // slidesToScroll={3}
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1.1,
        centerMode: false,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1.1,
        centerMode: false,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1.1,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 2050,
      settings: {
        slidesToShow: 1.1,
        centerMode: false,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomeSliderTop = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 1;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 1;
  } else if (width > 1025 && width <= 1920) {
    slidesToShow = 1;
  } else {
    slidesToShow = 1;
  }

  const { data, isLoading } = useQuery({
    queryKey: ["home-data"],
    queryFn: () => {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/api/home`, {
        userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
      });
    },
  });
  let sliderMovieToRender = data?.data.body.sliders.filter((seriesAndMovies) => seriesAndMovies.movie);
  let sliderSeriesToRender = data?.data.body.sliders.filter((seriesAndMovies) => seriesAndMovies.series);
  // console.log(data?.data.body.canPlay);
  return (
    <div className={classes.carousel}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Slider {...carouselProperties}>
          {sliderMovieToRender?.map((item) => (
            <MovieSliderCard windowW={width} item={item.movie} canPlay={data?.data.body.canPlay} image={item.image} key={item.id} />
          ))}
          {sliderSeriesToRender?.map((item) => (
            <SeriesSliderCard windowW={width} series={item.series} canPlay={data?.data.body.canPlay} image={item.image} key={item.id} />
          ))}
        </Slider>
      )}
    </div>
  );
};

const MovieSliderCard = ({ item, image, canPlay, windowW }) => {
  const [isSwiping, setIsSwiping] = useState(false);
  const handleSwipeStart = () => {
    setIsSwiping(true);
  };

  const handleSwipeEnd = () => {
    setIsSwiping(false);
  };

  const handleLinkClick = (e) => {
    if (isSwiping) {
      e.preventDefault(); // Swipe işlemi sırasında tıklamayı engelle
    } else if (windowW < 592) {
      navigate(`/moviedetail/${item?.movie_id}`);
    }
  };

  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();

  // console.log("can play", data?.data.body);
  return (
    <div
      className={classes.card}
      onTouchStart={handleSwipeStart}
      onTouchEnd={handleSwipeEnd}
      onClick={(e) => {
        handleLinkClick();
        e.stopPropagation();
      }}
    >
      <img
        className={classes.multiImage}
        src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
        alt=""
        style={{
          objectFit: "cover",
        }}
      />
      <div className={classes.cardContent}>
        <div className={classes.title}>
          <h1>{item.movie_title}</h1>
          <div className={classes.category}>
            <img src={star} alt="star" />
            <h3>{item.avg_rating}</h3>
            <p>
              · {item.genre_title} · {item.movie_year} · {item.movie_duration}
            </p>
          </div>
          <p className={classes.detailPara}>{item.movie_description}</p>
        </div>
        <div className={classes.watchBtns}>
          <button
            className={classes.watchNow}
            onClick={(e) => {
              if (!currentUser.currentUser) {
                navigate("/signin");
                e.stopPropagation();
              } else canPlay ? navigate(`/watch/${item?.movie_id}`) : navigate("choosepayment");
              e.stopPropagation();
            }}
            style={{ cursor: "pointer" }}
          >
            Watch now
          </button>
          <button
            className={classes.watchTrailer}
            onClick={(e) => {
              navigate(`/moviedetail/${item?.movie_id}#trailer`);
              e.stopPropagation();
            }}
            // onClick={() => navigate("/moviedetail", { state: item.movie_id })}
          >
            <span>Play trailer</span>
          </button>
          <button
            className={classes.moreDetail}
            onClick={(e) => {
              handleLinkClick();
              e.stopPropagation();
            }}
          >
            <span>More</span>
          </button>
        </div>
      </div>
    </div>
  );
};
const SeriesSliderCard = ({ series, image, canPlay, windowW }) => {
  const [isSwiping, setIsSwiping] = useState(false);
  const handleSwipeStart = () => {
    setIsSwiping(true);
  };

  const handleSwipeEnd = () => {
    setIsSwiping(false);
  };

  const handleLinkClick = (e) => {
    if (isSwiping) {
      e.preventDefault(); // Swipe işlemi sırasında tıklamayı engelle
    } else if (windowW < 592) {
      navigate(`/seriesdetail/${series.serie_id}#details`);
    }
  };

  // console.log(series);
  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div
      className={classes.card}
      onTouchStart={handleSwipeStart}
      onTouchEnd={handleSwipeEnd}
      onClick={(e) => {
        handleLinkClick();
        e.stopPropagation();
      }}
    >
      <img
        className={classes.multiImage}
        src={`${process.env.REACT_APP_BASE_URL}/images/${image}`}
        alt=""
        style={{
          objectFit: "cover",
        }}
      />
      <div className={classes.cardContent}>
        <div className={classes.title}>
          <h1>{series?.serie_title}</h1>
          <div className={classes.category}>
            <img src={star} alt="star" />
            <h3>{series?.avg_rating}</h3>
            <p>
              · {series?.serie_title} · {series?.serie_year}
            </p>
          </div>
          <p className={classes.detailPara}>{series?.serie_description}</p>
        </div>
        <div className={classes.watchBtns}>
          <button
            className={classes.watchNow}
            onClick={(e) => {
              if (!currentUser.currentUser) {
                navigate("/signin");
                e.stopPropagation();
              } else canPlay ? navigate(`/watch/${series.serie_id}`) : navigate("choosepayment");
              e.stopPropagation();
            }}
            style={{ cursor: "pointer" }}
          >
            Watch now
          </button>
          <button
            className={classes.watchTrailer}
            onClick={(e) => {
              navigate(`/moviedetail/${series.serie_id}#trailer`);
              e.stopPropagation();
            }}
            // onClick={() => navigate("/moviedetail", { state: item.movie_id })}
          >
            <span>Play trailer</span>
          </button>
          <button
            className={classes.moreDetail}
            onClick={(e) => {
              navigate(`/seriesdetail/${series.serie_id}#details`);
              e.stopPropagation();
            }}
          >
            <span>More</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeSliderTop;
