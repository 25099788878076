import React, { useContext, useState } from "react";
import ClientReviews from "./ClientReviews";
import classes from "./Reviews.module.css";
import StarRating from "./Star";
import { makeReqest } from "../../axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthContext } from "../../context/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Reviews = ({ seriesId, reviews }) => {
  const [review, setReview] = useState({
    user: "",
    content: "",
    rating: 0,
    fk_user_id: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
  });
  const currentUser = useContext(AuthContext);

  const queryClient = useQueryClient();
  const addReview = useMutation({
    mutationFn: () => {
      return makeReqest.post(`series/${seriesId}`, review);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["seriesDetail"]);
      queryClient.invalidateQueries(["serie"]);
      setReview({
        user: "",
        content: "",
        rating: 0,
        fk_user_id: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
      });
      toast.success("Successfully add review", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movies-error",
      });
    },
  });
  const handlestarReview = (stars) => {
    setReview((prev) => ({ ...prev, rating: stars }));
  };
  const handleChange = (e) => {
    setReview((prev) => ({ ...prev, content: e.target.value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setReview((prev) => ({
      ...prev,
      user: JSON.parse(localStorage.getItem("user")).body.user.user_name,
    }));
    addReview.mutate();
  };
  // console.log(addReview.data.data.body);
  return (
    <div className={classes.reviewsTop}>
      {currentUser.currentUser && (
        <form className={classes.addReviews} onSubmit={handleSubmit}>
          <h2>Please rate this movie</h2>
          <StarRating handlestarReview={handlestarReview} />
          <textarea
            placeholder="Type your review here..."
            value={review.content}
            onChange={handleChange}
          />
          <button className={classes.post} type="submit">
            Post
          </button>
        </form>
      )}

      <ClientReviews seriesReviews={reviews} />
      <ToastContainer limit={1} />
    </div>
  );
};

export default Reviews;
