import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Next from "./../../StaticPic/Next.png";
import classes from "./MoviesSlider.module.css";
import SliderCard from "./SliderCard";
import Spinner from "../spinner/Spinner.jsx";

let slidesToShow = 5;
const PreviousBtn = (props) => {
  const { className, onClick, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick} style={{ left: "-60px", zIndex: "90", width: "50px", height: "50px" }}>
        <img className={classes.carouselPrev} src={Next} alt="next" />
      </div>
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick, slideCount, currentSlide } = props;
  return (
    <>
      <div className={className} onClick={onClick} style={{ right: "-30px" }}>
        <img className={classes.carouselNext} src={Next} alt="next" />
      </div>
    </>
  );
};

const MoviesSlider = ({ title, data, isLoading }) => {
  const [isClickAllowed, setIsClickAllowed] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const carouselProperties = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: true,
    // slidesToScroll={3}
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.1,
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2050,
        settings: {
          slidesToShow: 4,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      setIsClickAllowed(false);
    },
    afterChange: (current) => {
      setIsClickAllowed(true);
    }
  };

  if (width <= 426) {
    slidesToShow = 1;
  } else if (width > 426 && width <= 769) {
    slidesToShow = 2;
  } else if (width > 769 && width <= 1025) {
    slidesToShow = 3;
  } else if (width > 1025 && width <= 1440) {
    slidesToShow = 3;
  } else {
    slidesToShow = 4;
  }

  return (
    <div className={classes.carousel}>
      <h2>{title}</h2>
      {isLoading ? (
        <Spinner />
      ) : (
        <Slider {...carouselProperties}>
          {data?.map((item) => (
            <div className={classes.card} key={item.movie_id}>
              <SliderCard movieItem={item} isClickAllowed={isClickAllowed} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default MoviesSlider;
