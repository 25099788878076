import React, { useContext } from "react";
import Recommended from "../RecommendedMovie/Recommended";
import FeatureMovie from "./FeatureMovie/FeatureMovie";
import FeaturedSeries from "./FeatureSeries/FeaturedSeries";
import HighRating from "./HighRating/HighRating";
import HomeSliderTop from "./HomeSliderTop/HomeSliderTop";
import LatestEpisode from "./LatestEpisode/LatestEpisode";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import RecentMovies from "./RecentMovies/RecentMovies";
import RecentSeries from "./RecentSeries/RecentSeries";
// import TopMovies from "./TopMovies/TopMovies";
import TopSeries from "./TopSeries/TopSeries";
import { AuthContext } from "../../context/authContext";

import TopMoviesNew from "./_TopMovies/index";
import TopSeriesNew from "./_TopSeries/index";

const HomeContent = () => {
  const { currentUser } = useContext(AuthContext);
  console.log("CURRENT", currentUser);
  return (
    <>
      <HomeSliderTop />
      <Recommended />
      {!currentUser || currentUser?.body.user.user_status === false ? <PaymentDetail /> : null}
      {/* <TopMovies /> */}
      <TopMoviesNew />
      <TopSeriesNew />
      <FeaturedSeries />
      <RecentSeries />
      <LatestEpisode />
      <HighRating />
      <FeatureMovie />
      <RecentMovies />
    </>
  );
};

export default HomeContent;
