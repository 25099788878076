import React, { useContext, useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import SocialShare from "../SocialShare/SocialShare";
import classes from "./MovieDetail.module.css";
import star from "./../../img/star.svg";
import MultiItemCarousel from "../SliderSeason/SliderSeason";
import Recommended from "../RecommendedMovie/Recommended";
import { useNavigate, useParams } from "react-router";
import { makeReqest } from "../../axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import MoviesReviews from "./MoviesReviews/MoviesReviews";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import Spinner from "../spinner/Spinner";
import filledWatchLaterIcon from "../../img/BookmarkSimple.svg";
import saveBook from "../../img/Icons.svg";

const MovieDetail = () => {
  const { id } = useParams();
  const [trailerKey, setTrailerKey] = useState("");

  const [showCategory, setIsShowCategory] = useState(false);
  const [movies, setMovies] = useState(null);
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["moviesDetail", id],
    queryFn: () => {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/api/movies/${id}`, {
        userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
      });
    },
  });

  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const addToWatchList = useMutation({
    mutationFn: () => {
      return makeReqest.post(`wishlist/movies/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["userHistory"]);
      queryClient.invalidateQueries(["watchList"]);
      queryClient.invalidateQueries(["seriesDetail"]);
    },
  });

  useEffect(() => {
    if (data) {
      setMovies(data?.data.body.movie);
      const regex1 = /(?:\?v=)([\w-]+)/;

      // Regular expression to match the video ID (the "v" value) with "youtu.be" format
      const regex2 = /(?:\.be\/)([\w-]+)/;

      // Function to extract the video ID from the URL
      const extractVideoID = (url) => {
        const match1 = url.match(regex1);
        const match2 = url.match(regex2);

        if (match1) {
          return match1[1];
        } else if (match2) {
          return match2[1];
        } else {
          return null;
        }
      };

      console.log(currentUser);

      // Test with the URLs
      const videoID1 = extractVideoID(data?.data.body.movie.movie_trailer);
      setTrailerKey(videoID1);
    }
    // vidRef.current.play();
  }, [data]);

  useEffect(() => {
    // Scroll to the section when the component mounts or the URL changes
    const sectionId = window.location.hash.substring(1);
    console.log("section id", sectionId);
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [window.location.pathname]);

  return (
    <div className={classes.detailTop} id="details">
      <div className={classes.movieInner}>
        <div className={classes.headerTop}>
          {/* <video ref={vidRef} src={movies?.movie_trailer} loop></video> */}
          <iframe
            style={{ transform: "translateY(-120px)" }}
            src={`https://www.youtube.com/embed/${trailerKey}?autoplay=1&loop=1&controls=0&showinfo=0&mute=1`}
            // title="YouTube video player"
            frameborder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            // allowfullscreen
          ></iframe>

          <div class={classes.overlay}>
            <Header />

            {isLoading ? (
              <Spinner />
            ) : (
              movies && (
                <div className={classes.movieContent}>
                  <div className={classes.left}>
                    {/* <img className={classes.poster} src={poster} alt="poster" /> */}
                    <img className={classes.poster} src={`${process.env.REACT_APP_BASE_URL}/images/${movies.movie_image}`} alt="top series" />
                    <div className={classes.detailMovie}>
                      <div className={classes.top}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className={classes.saveSeries}>
                            <h2 style={{ marginBottom: "0px" }}>{movies.movie_title}</h2>
                            <div>
                              {currentUser?.body.user.user_status && (
                                <img
                                  className={classes.bookmarkDesktop}
                                  src={data?.data.body.movie.is_wishlist ? filledWatchLaterIcon : saveBook}
                                  alt="save"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addToWatchList.mutate();
                                  }}
                                />
                              )}
                              {/* <img
                                className={classes.bookmarkDesktop}
                                src={saveBook}
                                alt="saveBook"
                              /> */}
                            </div>
                          </div>
                        </div>
                        <div className={classes.rating}>
                          <div className={classes.newRow}>
                            <div className={classes.totalRate}>
                              <img src={star} alt="star" />
                              <p>10</p>
                            </div>
                            <div className={`${classes.subtitle} ${classes.subtitle1}`}>
                              <p>Subtitles: Eng</p>
                            </div>
                          </div>
                          <p className={classes.genre}>
                            {movies.genres.map((genre) => `${genre.genre_title}, `)} &nbsp; . &nbsp; {movies.movie_year} &nbsp; . &nbsp; {movies.movie_duration}
                          </p>
                        </div>
                        <div className={`${classes.subtitle} ${classes.subtitle2}`}>
                          <p>Subtitles: Eng</p>
                        </div>
                        <h2>Description</h2>
                        <p
                          className={classes.descrip}
                          style={{
                            height: "200px",
                            // border: "1px solid black",
                            overflow: "hidden",
                            // overflowY: "scroll",
                            marginBottom: "20px",
                          }}
                        >
                          {/* <style>
                            {`
          ::-webkit-scrollbar {
            width: 6px;
          }

          ::-webkit-scrollbar-track {
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            background-color: #888;
          }
        `}
                          </style> */}
                          {movies.movie_description}
                        </p>
                      </div>
                      <div className={classes.btns}>
                        <button
                          onClick={() => {
                            if (!currentUser) return navigate("/signin");
                            else data?.data.body.canPlay ? navigate(`/watch/${movies.movie_id}`) : navigate("/choosepayment");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Watch Now
                        </button>
                        <button
                          onClick={() => {
                            let element = document.getElementById("trailer");
                            element.scrollIntoView({ behavior: "smooth" });
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <span>Play Trailer</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {showCategory && <MultiItemCarousel />}

        {movies?.casts.length != 0 && (
          <div className={classes.topcast}>
            <h2>Top Cast</h2>
            <div className={classes.row}>
              {movies?.casts.map((cast) => (
                <div className={classes.cast}>
                  <img src={cast?.imageUrl} alt="cast" />
                  <p>{cast?.name}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={classes.trailer} id="trailer">
          <h2>Trailer</h2>
          {movies && (
            <iframe
              style={{
                maxWidth: "1100px",
                width: "100%",
                margin: "auto",
                height: "600px",
                maxHeight: "600px",
                borderRadius: "20px",
              }}
              src={`https://www.youtube.com/embed/${trailerKey}`}
              // title="YouTube video player"
              frameborder="0"
              // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              // allowfullscreen
              allowfullscreen="allowfullscreen"
              mozallowfullscreen="mozallowfullscreen"
              msallowfullscreen="msallowfullscreen"
              oallowfullscreen="oallowfullscreen"
              webkitallowfullscreen="webkitallowfullscreen"
            ></iframe>
          )}
        </div>

        <div className={classes.social}>
          <SocialShare />
          {/* <Reviews seriesId={location.state} reviews={movies.movies_reviews} /> */}
          <MoviesReviews movieId={movies?.movie_id} reviews={movies && movies.movies_reviews} />
        </div>
      </div>
      <Recommended />

      <Footer />
    </div>
  );
};

export default MovieDetail;
