import React, { useState } from "react";
import classes from "./Rate.module.css";
import { AiFillStar } from "react-icons/ai";
class InitializationText extends React.Component {
  render() {
    return <div className={classes.red}></div>;
  }
}

class StarChooser extends React.Component {
  getActiveStar = (value) => {
    return this.props.stars >= value ? ` ${classes.active}` : "";
  };

  setStar = (value) => {
    this.props._onSubmit(value);
  };

  render() {
    return (
      <div className={classes.topRate}>
        <div className={classes.starfield}>
          <div
            className={`${classes.star} ${this.getActiveStar(1)}`}
            onClick={() => this.setStar(1)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>1</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(2)}`}
            onClick={() => this.setStar(2)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>2</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(3)}`}
            onClick={() => this.setStar(3)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>3</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(4)}`}
            onClick={() => this.setStar(4)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>4</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(5)}`}
            onClick={() => this.setStar(5)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>5</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(6)}`}
            onClick={() => this.setStar(6)}
          >
            <AiFillStar className={classes.satrIcon} />

            <p>6</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(7)}`}
            onClick={() => this.setStar(7)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>7</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(8)}`}
            onClick={() => this.setStar(8)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>8</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(9)}`}
            onClick={() => this.setStar(9)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>9</p>
          </div>
          <div
            className={`${classes.star} ${this.getActiveStar(10)}`}
            onClick={() => this.setStar(10)}
          >
            <AiFillStar className={classes.satrIcon} />
            <p>10</p>
          </div>
        </div>
      </div>
    );
  }
}

// class Commender extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       message: "",
//     };
//   }

//   componentWillReceiveProps(newProps) {
//     let a = {
//         1: "Oh. Sorry you had a bad experience :(",
//         2: "We will try to improve.",
//         3: "Appreciate it!",
//         4: "Thank you! :0",
//         5: "You're Awesome! :)",
//       },
//       b = newProps.rate;

//     this.setState({
//       rating: b,
//       message: a[b],
//     });
//   }

//   render() {
//     if (this.props.rate !== 0 && this.state.message.length) {
//       return (
//         <div id="commender">
//           <p className="commender_tit">
//             You rated this{" "}
//             {`${this.props.rate} ${this.props.rate > 1 ? `${classes.stars}` : `${classes.star}`}`}
//           </p>
//           <p className="commender_tit">{this.state.message}</p>
//         </div>
//       );
//     } else {
//       return null;
//     }
//   }
// }

class Rate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      choosedStars: 0,
    };
    // props.stars(this.state.choosedStars);
  }

  setStar = (value) => {
    this.setState({ choosedStars: value });
    this.props.selectedStars(value);
  };

  render() {
    return (
      <div className={classes.rateCenter}>
        <InitializationText />
        <StarChooser stars={this.state.choosedStars} _onSubmit={this.setStar} />
        {/* <Commender rate={this.state.choosedStars} /> */}
      </div>
    );
  }
}

export default Rate;
