import classes from './SignInForm.module.css';
import googleLogo from './../../img/googleLogo.svg';
import React, {useContext, useEffect} from 'react';
import eyePass from './../../img/Eyepassword.svg';
import eyeTxt from './../../img/Eyetxt.svg';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {AuthContext} from '../../context/authContext';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {useMutation, useQuery} from '@tanstack/react-query';

const SignInForm = () => {
	const [passwordType, setPasswordType] = useState('password');
	const [passwordInput, setPasswordInput] = useState('');
	const [email, setEmail] = useState('');
	const [gmailToken, setGmailToken] = useState();
	const location = useLocation();
	const {currentUser} = useContext(AuthContext);
	const [FCMToken, setFCMToken] = useState('');
	const [deviceId, setDeviceID] = useState('');

	useEffect(() => {
		const token = new URLSearchParams(location.search).get('token');
		if (token) setGmailToken(token);
	}, [gmailToken]);

	const {data, isLoading} = useQuery({
		queryKey: ['googleSignIn'],
		queryFn: () => {
			return axios.post(
				`${process.env.REACT_APP_BASE_URL}/api/users/google/profile`,
				{token: gmailToken}
			);
		},
		enabled: !!gmailToken,
		onSuccess: (data) => {
			localStorage.setItem('user', JSON.stringify(data.data));
			window.location.href = '/';
		},
	});

	// const [enabledGoogleAuth,setEnabledGoogleAuth] = useState(false)
	const handlePasswordChange = (evnt) => {
		setPasswordInput(evnt.target.value);
	};
	const togglePassword = () => {
		if (passwordType === 'password') {
			setPasswordType('text');
			return;
		}
		setPasswordType('password');
	};

	const {login} = useContext(AuthContext);
	const navigate = useNavigate();
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await login({email, inputPassword: passwordInput});
			window.location.href = '/';
		}
		catch (err) {
			// console.log(err.response.data.error);

			toast.error(err.response.data.error, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
		}
	};

	function handleGoogleSignIn() {
		const googleAuthUrl = `${process.env.REACT_APP_BASE_URL}/api/users/signin/google`;

		// Open the Google authentication link in the current tab
		const authWindow = window.open(googleAuthUrl, '_self');
	}

	return (
		<>
			{isLoading && gmailToken ? (
				<div className="flex items-center justify-center m-14">
					<div className={classes.spinner}></div>
				</div>
			) : (
				<div className={classes.SignIn}>
					<h1>Welcome Back</h1>
					<p>Please enter your details here</p>

					<button className={classes.googleBtn} onClick={handleGoogleSignIn}>
            <span>
              <img src={googleLogo} alt="googleLogo"/>
              Sign In with Google
            </span>
					</button>
					<div className={classes.orRow}>
						<span className={classes.line}></span>
						<p>OR</p>
						<span className={classes.line}></span>
					</div>
					<form onSubmit={handleSubmit}>
						<div className={classes.inputStroke}>
              <span>
                <input
	                type="email"
	                placeholder="E-mail"
	                onChange={(e) => setEmail(e.target.value)}
                />
              </span>
						</div>
						<div className={classes.pass}>
              <span>
                <input
	                type={passwordType}
	                onChange={handlePasswordChange}
	                value={passwordInput}
	                name="password"
	                placeholder="Password"
                />
                <div>
                  <button type="button" onClick={togglePassword}>
                    {passwordType === 'password' ? (
	                    <img src={eyePass} alt="eyePass"/>
                    ) : (
	                    <img src={eyeTxt} alt="eyeTxt"/>
                    )}
                  </button>
                </div>
              </span>
						</div>
						<div class={classes.container}>
							<Link to={'/forgotpassword'}>
								{' '}
								<p>Forgot password?</p>
							</Link>
						</div>
						<button className={classes.createBtn} type="submit">
							Log In
						</button>
					</form>
					<span>
            Don’t have an account?{' '}
						<Link to={'/register'}>
              <p>Register</p>
            </Link>
          </span>
					<ToastContainer limit={1}/>
				</div>
			)}
		</>
	);
};
export default SignInForm;
