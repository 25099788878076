import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import SeriesSlider from "../../_SeriesSlider";

const FeaturedSeries = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["home-data"],
    queryFn: () => {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/api/home`, {
        userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
      });
    },
  });
  return <SeriesSlider title={"Featured Series"} data={data?.data.body.featuredSeries} isLoading={isLoading} />;
};

export default FeaturedSeries;
