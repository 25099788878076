import classes from "./MoviePlayer.module.css";
import React from "react";
import Spinner from "../spinner/Spinner";
import SocialShare from "../SocialShare/SocialShare";
import Recommended from "../RecommendedMovie/Recommended";
import { useParams } from "react-router";
import MoviesReviews from "../MovieDetail/MoviesReviews/MoviesReviews";
import { useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../axios";

const MoviePlayer = () => {
  const { id } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ["watch-movie"],
    queryFn: () => {
      return makeReqest.get(`movies/${id}`);
    },
  });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className={classes.moviePlayerTop}>
            <div className={classes.headerPlayer}>
              <>
                <h2>{data?.data.body.movie?.movie_title}</h2>
                {/* <img className={classes.info} src={info} alt="info" /> */}
              </>
            </div>
            <div className={classes.videoPlayer}>
              <iframe
                title="myFrame"
                src={data?.data.body.movie.movie_link}
                width="100%"
                height="500"
                frameborder="0"
                allowfullscreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
            </div>
            <SocialShare />

            <MoviesReviews
              movieId={data?.data.body.movie.movie_id}
              reviews={data?.data.body.movie.movies_reviews}
            />
          </div>
          <Recommended />
        </div>
      )}
    </>
  );
};

export default MoviePlayer;
