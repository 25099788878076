import React, { useState } from "react";
import Filter from "../Filter/Filter";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import classes from "./AllMovies.module.css";
import MovieCard from "./MovieCard";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import axios from "axios";
import Spinner from "../spinner/Spinner";
import NoResult from "../Search/NoResult";

const AllMovies = () => {
  const [loadMore, setLoadMore] = useState(1);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const {
    data: infinitData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["loadMovies"],
    queryFn: ({ pageParam = 0 }) => {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/movies?page=${pageParam}&limit=16&sortBy=movie_year&sortOrder=desc`,
        { userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id }
      );
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.body.movies.length > 0) {
        return loadMore;
      } else {
        return undefined;
      }
    },
  });
  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const { data: searchResults, isFetching } = useQuery({
    queryKey: ["searchmovies", query],
    queryFn: () => {
      return makeReqest.get(
        `${process.env.REACT_APP_BASE_URL}/api/movies/search?q=${query}`
      );
    },
    enabled: !!query,
  });
  const { data: filteredMovies, isLoading: filterIsLoading } = useQuery({
    queryKey: ["filteredMovies", filteredData],
    queryFn: () => {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/movies/filters?page=${loadMore}`,
        filteredData
      );
    },
    enabled: !!filteredData,
  });

  return (
    <div className={classes.allMoviesTop}>
      <div className={classes.allmoviesInner}>
        <Header />
        <div className={classes.moviesList}>
          <Filter
            handleFilterData={setFilteredData}
            handleSearch={handleSearch}
          />
          <h2>All Movies</h2>
          <div className={classes.row}>
            {
              // (filteredMovies?.data.body.movies.length === 0 ||
              //   !filteredMovies) &&
              isLoading || isFetching || filterIsLoading ? (
                <Spinner />
              ) : (
                infinitData?.pages.map((page) => {
                  const moviesToRender =
                    !query && filteredMovies?.data.body.movies.length === 0
                      ? page
                      : query && filteredMovies?.data.body.movies.length === 0
                      ? searchResults
                      : filteredMovies;
                  return (
                    moviesToRender &&
                    moviesToRender.data.body.movies.map((movie) => (
                      <MovieCard movie={movie} key={movie.movie_id} />
                    ))
                  );
                })
              )
            }
            {searchResults?.data.body.movies.length <= 0 && query && (
              <NoResult />
            )}
            {/* {filteredMovies?.data.body.movies.map((movie) => (
              <MovieCard movie={movie} />
            ))} */}
          </div>
          {infinitData?.pages.length > 0 && !query && (
            <button
              className={classes.loadMoreBtn}
              onClick={() => {
                fetchNextPage();
                setLoadMore((prev) => prev + 1);
              }}
              disabled={!hasNextPage}
            >
              <span>Show more</span>
            </button>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AllMovies;
