import React, { useContext } from "react";
import classes from "./SeriesCard.module.css";
import star from "./../../img/star.svg";
import save from "./../../img/save.svg";
import bookmark from "./../../img/bookmark.svg";
import NotificationFilled from "./../../img/notificationFilled.svg";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import filledWatchLaterIcon from "./../../img/BookmarkSimple.svg";
import { AuthContext } from "../../context/authContext";

const SeriesCard = ({ serie }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useContext(AuthContext);
  const addToWatchList = useMutation({
    mutationFn: () => {
      return makeReqest.post(`wishlist/series/${serie.serie_id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["userHistory"]);
      queryClient.invalidateQueries(["watchList"]);
    },
  });
  const addToNotification = useMutation({
    mutationFn: () => {
      return makeReqest.get(`user_notifications/${serie.serie_id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["projects"]);
      queryClient.invalidateQueries(["userHistory"]);
      queryClient.invalidateQueries(["watchList"]);
    },
  });
  // console.log(serie);
  return (
    <div
      className={classes.cardUiTop}
      onClick={() => navigate(`/seriesdetail/${serie.serie_id}#details`)}
    >
      <div className={classes.headerCard}>
        <div className={classes.rating}>
          <div>
            <img className={classes.star} src={star} alt="star" />
            <h3>10</h3>
          </div>
          {currentUser.currentUser?.body.user.user_status && (
            <img
              className={classes.save}
              src={serie?.is_wishlist ? filledWatchLaterIcon : save}
              alt="save"
              onClick={(e) => {
                e.stopPropagation();
                addToWatchList.mutate();
              }}
            />
          )}

          {currentUser.currentUser?.body.user.user_status && (
            <img
              className={classes.bookmark}
              // style={{width:'48px',height:'48px'}}
              src={serie?.is_notification_on ? NotificationFilled : bookmark}
              // src={bookmark}
              alt="bookmark"
              onClick={(e) => {
                e.stopPropagation();
                addToNotification.mutate();
              }}
            />
          )}
        </div>
        <img
          className={classes.imgDefault}
          src={`${process.env.REACT_APP_BASE_URL}/images/${serie?.serie_image}`}
          alt="top series"
          // style={{ width: "400px", height: "560px" }}
        />

        <div className={classes.hoverCard}>
          <div className={classes.details}>
            <h4>{serie?.serie_title}</h4>
            {/* <p>{serie?.serie_description}</p> */}
            {/* <CharacterLimit text={serie?.serie_description} limit={190} /> */}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <h3>{serie?.serie_title}</h3>
        <p>
          <span>{serie?.serie_year} </span>
          {/* {!serie?.genres?.length >= 0 && (
            <span
              style={{ position: "relative", top: "-4px", marginLeft: "10px" }}
            >
              .
            </span>
          )} */}

          {/* <span style={{ marginLeft: "10px" }}>
            {serie?.genres?.map(
              (genre, index) =>
                `${genre.genre_title}${
                  serie.genres.length - 1 !== index ? "," : ""
                }`
            )}
          </span> */}
        </p>
      </div>
    </div>
  );
};
export default SeriesCard;
