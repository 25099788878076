import multicar13 from "./assets/poster2.png";
import multicar14 from "./assets/poster2.png";
import multicar15 from "./assets/poster2.png";
import multicar16 from "./assets/poster2.png";
import multicar17 from "./assets/poster2.png";
import multicar18 from "./assets/poster2.png";
import multicar19 from "./assets/poster2.png";
import multicar20 from "./assets/poster2.png";
import multicar21 from "./assets/poster2.png";
import multicar22 from "./assets/poster2.png";
import multicar23 from "./assets/poster2.png";

export const multiData = [
  multicar13,
  multicar14,
  multicar15,
  multicar16,
  multicar17,
  multicar18,
  multicar19,
  multicar20,
  multicar21,
  multicar22,
  multicar22,
  multicar23,
];
