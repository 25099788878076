import React from "react";
import classes from "./CreateAccount.module.css";
import loginGif from "./../../img/loginGif.gif";
import CreateForm from "./CreateForm";
import logo from "./../../img/Logo.svg";
import { useNavigate } from "react-router";

const CreateNew = () => {
  const navigate = useNavigate();
  return (
    <div className={classes.CreateTop}>
      <div className={classes.row}>
        <div className={classes.left}>
          <div
            className={classes.logo}
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            <img src={logo} alt="logo" />
            <h2>WatchTurks </h2>
          </div>
          <CreateForm />
        </div>
        <div className={classes.right}>
          <img src={loginGif} alt="Gif" />
        </div>
      </div>
    </div>
  );
};
export default CreateNew;
