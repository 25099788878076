import Cookies from 'js-cookie';
import {createContext, useEffect, useState} from 'react';
import axios from 'axios';
import React from 'react';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
	const [currentUser, setCurrentUser] = useState(
		JSON.parse(localStorage.getItem('user')) || null
	);

	const login = async (inputs) => {
		const user = await axios.post(
			`${process.env.REACT_APP_BASE_URL}/api/users/signin`,
			inputs
		);
		setCurrentUser(user.data);
	};
	useEffect(() => {
		localStorage.setItem('user', JSON.stringify(currentUser));
	}, [currentUser]);
	{
		currentUser &&
		Cookies.set('token', currentUser.body.token, {expires: 24});
	}
	return (
		<AuthContext.Provider value={{currentUser, login}}>
			{children}
		</AuthContext.Provider>
	);
};
