import React, { useContext, useState } from "react";
import classes from "./Contact.module.css";
import ChatCircleDots from "./../../../img/ChatCircleDots.svg";
import WhatsappLogo from "./../../../img/WhatsappLogo.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import { leftMenu } from "../ProfileMain";
import { useMutation } from "@tanstack/react-query";
import { makeReqest } from "../../../axios";
import AccordionItem from "../Faqs/Accordion";
import faq from "./../../../img/Info.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const faqs = [
  {
    id: 1,
    header: "How can I reset my password?",
    text: `If you can't log in to your account, you should click the "Forgot Password" button on the login page.
If you are logged in and want to change your password, you can click the "Edit Profile" section on the menu.`,
  },
  {
    id: 2,
    header: "Are your Series and Movies with English subtitles?",
    text: `Yes, our all Turkish series and movies with English Subtitles. We don't provide any other languages. `,
  },
  {
    id: 3,
    header: "Do you have a trial period?",
    text: `Yes, you can click the "Try 2 New Episodes" button after you sign up. Then, you need to buy a subscription plan.`,
  },
  {
    id: 4,
    header: "How can I renew my subscription?",
    text: `You can go to "Subscription" section on the menu and choose your new plan.`,
  },
];

const Contact = () => {
  const ctxContact = useContext(leftMenu);
  const [text, setText] = useState("");
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const contactAdmin = useMutation({
    mutationFn: () => {
      return makeReqest.post("contact-us", { message: text });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries(["movies"]);
      toast.success("Email Send successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movie-success",
      });
    },
    onError: (error) => {
      toast.error(error.response.data.message || error.response.data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movies-error",
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    contactAdmin.mutate();
  };
  const showSidebar = () => {
    ctxContact();
  };
  return (
    <div className={classes.contactTop}>
      <div className={classes.headerContent}>
        <div className={classes.contactInner}>
          <img
            className={classes.caretLeft}
            onClick={showSidebar}
            src={CaretLeft}
            alt="CaretLeft"
          />
          <img
            className={classes.ChatCircleDots}
            src={ChatCircleDots}
            alt="ChatCircleDots"
          />
          <h1>Contact Us</h1>
        </div>
        <a href="https://wa.me/447312383516">
          <button>
            <span>
              <img src={WhatsappLogo} alt="WhatsappLogo" /> Contact in WhatsApp
            </span>
          </button>
        </a>
      </div>
      <textarea
        placeholder="Type your message here..."
        onChange={(e) => setText(e.target.value)}
      />
      <button
        className={classes.sendMessage}
        // type="submit"
        onClick={handleSubmit}
      >
        Send message
      </button>
      <div>
        <div className={classes.headerFaqs}>
          <div
            className={classes.row}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              color: "white",
              marginBottom: "15px",
              marginTop: "40px",
            }}
          >
            <img
              src={faq}
              alt="faq"
              style={{ width: "40px", height: "40px", alignSelf: "center" }}
            />
            <h1
              style={{
                fontStyle: "normal",
                fontWeight: " 700",
                fontSize: "40px",
                lineHeight: " 130%",
                color: "#ffffff",
                fontFamily: "Plus Jakarta Sans, sans-serif",
              }}
            >
              FAQs
            </h1>
          </div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              marginBottom: "24px",
              color: "#ffffff",
              fontFamily: "Plus Jakarta Sans, sans-serif",
              marginBottom: "50px",
            }}
          >
            Here are some of the most frequently asked questions
          </p>
        </div>
        {faqs.map((faq, index) => {
          return (
            <AccordionItem
              key={index}
              active={active}
              handleToggle={handleToggle}
              faq={faq}
            />
          );
        })}
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default Contact;
