import React, { useContext } from "react";
import classes from "./History.module.css";
import clock from "./../../../img/Clock.svg";
import NotSubscribe from "../../NotSubscribe/NotSubscribe";
import { useState } from "react";
import CaretLeft from "./../../../img/CaretLeft.svg";
import { leftMenu } from "../ProfileMain";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../../axios";
import MovieCard from "../../AllMovies/MovieCard";
import SeriesCard from "../../AllSeries/SeriesCard";
const History = () => {
  const { currentUser } = useContext(AuthContext);

  const ctx = useContext(leftMenu);
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
    ctx();
  };

  // const closeSideBar = () => {
  //   setSidebar(false);
  // }

  //   to just hide warnings
  const { data: history } = useQuery({
    queryKey: ["userHistory"],
    queryFn: () => {
      return makeReqest.get(`history`);
    },
  });
  console.log(history?.data.body.history);
  return (
    <div
      className={
        sidebar
          ? `${classes.historyTop} ${classes.active2}`
          : `${classes.historyTop}`
      }
    >
      {!currentUser?.body.user.user_status ? (
        <>
          <div className={classes.headerContent}>
            <img
              className={classes.CaretLeft}
              onClick={showSidebar}
              src={CaretLeft}
              alt="CaretLeft"
            />
            <img className={classes.bookmarkHeader} src={clock} alt="clock" />
            <h1>History</h1>
          </div>
          <div className={classes.moviesList}>
            {history?.data.body.history.map((movieAndSeries) => {
              return (
                <>
                  {" "}
                  {/* {console.log(movieAndSeries)} */}
                  {movieAndSeries.movie && (
                    <MovieCard movie={movieAndSeries.movie} />
                  )}
                  {movieAndSeries.series && (
                    <SeriesCard serie={movieAndSeries?.series} />
                  )}
                  {/* {m} */}
                </>
              );
            })}
          </div>
        </>
      ) : (
        <NotSubscribe />
      )}

      {/* {!currentUser?.body.user.user_status && <NotSubscribe />} */}
    </div>
  );
};
export default History;
