import React, {useContext, useEffect} from 'react';
import {Routes, Route, Navigate, useParams} from 'react-router';
import './App.css';
import AllMovies from './components/AllMovies/AllMovies';
import AllSeries from './components/AllSeries/AllSeries';
import CreateNew from './components/CreateAcount/CreateAccount';
import ForgotPass from './components/ForgotPass/ForgotPass';
import Home from './components/Home/Home';
import HomeContent from './components/Home/HomeContent';
import MovieDetail from './components/MovieDetail/MovieDetail';
import MoviePlayer from './components/MoviePlayer/MoviePlayer';
import NewPassword from './components/NewPassword/NewPassword';
import ChoosePayment from './components/Payment/ChoosePayment';
import Contact from './components/Profile/Contact/Contact';
import EditProfile from './components/Profile/EditProfile/EditProfile';
import Faqs from './components/Profile/Faqs/Faqs';
import History from './components/Profile/History/History';
import PrivacyPolicy from './components/Profile/PrivacyPolicy/PrivacyPolicy';
import ProfileMain from './components/Profile/ProfileMain';
import Subscription from './components/Profile/Subscription/Subscription';
import TermCondition from './components/Profile/TermCondition/TermCondition';
import WatchLater from './components/Profile/WatchLater/WatchLater';
import Search from './components/Search/Search';
import SignIn from './components/SignIn/SignIn';
import SeriesDetail from './components/AllSeries/SeriesDetail/SeriesDetail';
import {AuthContext} from './context/authContext';
import EpisodePlayer from './components/AllSeries/EpisodePlayer/EpisodePlayer';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {makeReqest} from './axios';
import {toast} from 'react-toastify';

function App() {
	const {currentUser} = useContext(AuthContext);

	let userId = currentUser?.currentUser?.body?.user?.user_id;

	const {data, isLoading} = useQuery({
		queryKey: ['user', userId],
		queryFn: () => {
			return makeReqest.get('');
		},
		onSuccess: (response) => {
			localStorage.setItem('user', JSON.stringify(response.data));
		},

		onError: (error) => {
			toast.error(error.response.data.message || error.response.data.error, {
				position: 'bottom-left',
				autoClose: 15000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
		},
	});

	useEffect(() => {

	});

	const UnauthenticatedRoute = ({children}) => {
		if (!currentUser?.body.user) {
			return <Navigate to="/signin"/>;
		}
		else {
			return children;
		}
	};

	const ProtectedContentRoute = ({children}) => {
		if (!currentUser?.body.user) {
			return <Navigate to="/signin"/>;
		}
		else if (currentUser?.body.user.user_status === false) {
			return <Navigate to="/choosepayment"/>;
		}
		else {
			return children;
		}
	};

	return (
		<div className="App">
			<>
				<Routes>
					<Route path="/" element={<Home/>}>
						<Route path="/" element={<HomeContent/>}/>
						<Route path="watch/:id" element={
							<ProtectedContentRoute>
								<MoviePlayer/>
							</ProtectedContentRoute>
						}/>
						<Route path="watchEpisodes/:id" element={<EpisodePlayer/>}/>
					</Route>
					<Route
						path="/moviedetail/:id"
						element={
							// <ProtectedRoute>
							<MovieDetail/>
							// </ProtectedRoute>
						}
					/>
					<Route
						path="/seriesdetail/:id"
						element={
							// <ProtectedRoute>
							<SeriesDetail/>
							// </ProtectedRoute>
						}
					/>

					<Route path="/register" element={<CreateNew/>}/>
					<Route path="/forgotpassword" element={<ForgotPass/>}/>
					<Route path="/newpassword" element={<NewPassword/>}/>
					<Route
						path="/choosepayment"
						element={
							!currentUser?.body.user ? (
								<Navigate to={'/signin'}/>
							) : (
								<ChoosePayment/>
							)
						}
					/>
					<Route
						path="/allseries"
						element={
							// <ProtectedRoute>
							<AllSeries/>
							// </ProtectedRoute>
						}
					/>
					<Route
						path="/allmovies"
						element={
							// <ProtectedRoute>
							<AllMovies/>
							// </ProtectedRoute>
						}
					/>
					<Route
						path="/search"
						element={
							// <ProtectedRoute>
							<Search/>
							// </ProtectedRoute>
						}
					/>
					<Route path="/" element={<ProfileMain/>}>
						<Route path="watch-later" element={
							<ProtectedContentRoute>
								<WatchLater/>
							</ProtectedContentRoute>
						}/>
						<Route path="history" element={
							<ProtectedContentRoute>
								<History/>
							</ProtectedContentRoute>
						}/>
						<Route
							path="edit-profile"
							element={
								<UnauthenticatedRoute>
									<EditProfile/>
								</UnauthenticatedRoute>
							}
						/>
						<Route
							path="subscription"
							element={
								!currentUser?.body.user ? (
									<Navigate to={'/signin'}/>
								) : (
									<Subscription/>
								)
							}
						/>
						<Route path="faq" element={<Faqs/>}/>
						<Route path="privacy-policy" element={<PrivacyPolicy/>}/>
						<Route path="term-condition" element={<TermCondition/>}/>
						<Route path="contact" element={<Contact/>}/>
					</Route>
					<Route path="/signin" element={<SignIn/>}/>
				</Routes>
			</>
		</div>
	);
}

export default App;
