import React, {useContext, useState} from 'react';
import classes from './Header.module.css';
import logo from './../../img/Logo.svg';
import SlidersHorizontal from './../../img/SlidersHorizontal.svg';
import MagnifyingGlass from './../../img/MagnifyingGlass.svg';
import User from './../../img/User.svg';
import menuIcon from './../../img/List.svg';
import notifyy from './../../img/notification.svg';
import pArrow from './../../img/pArrow.png';
import watchLater from './../../img/watchlater.svg';
import PencilSimple from './../../img/PencilSimple.svg';
import MonitorPlay from './../../img/MonitorPlay.svg';
import ShieldCheck from './../../img/ShieldCheck.svg';
import ListBullets from './../../img/ListBullets.svg';
import ChatCircleDots from './../../img/ChatCircleDots.svg';
import SignOut from './../../img/SignOut.svg';
import Logo from './../../img/Logo.svg';
import clock from './../../img/Clock.svg';
import cross from './../../img/Cross.svg';
import info from './../../img/Info.svg';
import homeIcon from './../../img/home-4.svg';
import barChart from './../../img/bar-chart-horizontal.svg';
import barMovieChart from './../../img/ph_film-script.svg';
import profileLogin from './../../img/Profile.svg';
import {Link, NavLink, useNavigate} from 'react-router-dom';
import {AuthContext} from '../../context/authContext';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {makeReqest} from '../../axios';
// import filterImg from "";

const Header = () => {
	const [showProfileMenu, setIsShowProfileMenu] = useState(false);
	const [showNotification, setIsShowNotification] = useState(false);
	const [showMobileMenu, setIsShowMobileMenu] = useState(false);
	const [query, setQuery] = useState('');
	const navigate = useNavigate();

	const handleSearch = (e) => {
		e.preventDefault();
		setQuery(e.target.value);
	};

	const {data: searchResults} = useQuery({
		queryKey: ['search', query],
		queryFn: () => {
			return axios.get(
				`${process.env.REACT_APP_BASE_URL}/api/home/search?q=${query}`
			);
		},
		enabled: !!query,
	});

	const {data: history} = useQuery({
		queryKey: ['userHistory'],
		queryFn: () => {
			return makeReqest.get(`history`);
		},
	});
	const {data: notification} = useQuery({
		queryKey: ['notification'],
		queryFn: () => makeReqest.get(`notifications`),
	});

	const openMobileMenu = () => {
		setIsShowMobileMenu(!showMobileMenu);
	};

	const showProfileHandlerToggle = () => {
		setIsShowProfileMenu(!showProfileMenu);
	};

	const showNotifHandlerToggle = () => {
		setIsShowNotification(!showNotification);
	};

	const {currentUser} = useContext(AuthContext);

	const logoutHandler = () => {
		localStorage.setItem('user', JSON.stringify(null));
	};

	// for mobile search
	const [showMobileSearch, setIsShowMobileSearch] = useState(true);
	const [setShowMobileSearchList, setIsShowMobileSearchList] = useState(false);
	// andler for show mobile Search ===
	const showMobileSearchHanlder = () => {
		setIsShowMobileSearchList(!setShowMobileSearchList);
	};
	// call function when search start so that dropdown show for mobile ====
	// console.log("kundi",);
	return (
		<div className={classes.HeaderTop}>
			<div
				className={
					showMobileMenu
						? `${classes.MobileMenuTop} ${classes.MobileMenuTopActive}`
						: `${classes.MobileMenuTop}`
				}
			>
				<div className={classes.menuForMobile}>
					<div className={classes.innerMenu}>
						<div className={classes.header}>
							<NavLink to="/">
                <span>
                  <img src={Logo} alt="logo"/>
                  <h3>WatchTurks </h3>
                </span>
							</NavLink>
							<img src={cross} onClick={openMobileMenu} alt="cros"/>
						</div>
						{!currentUser ? (
							// history?.data.body.history.length < 2 &&
							//  currentUser.body.user.subscription === null
							<button
								className={classes.twoEpisodeFreeMob}
								onClick={() => navigate('/signin')}
								style={{border: '0px'}}
							>
								<p>Try 2 episodes</p>
								<h3>FOR FREE</h3>
							</button>
						) : currentUser &&
						    currentUser.body.user.user_status === false &&
						    history?.data.body.history.length < 2 ? (
							<button
								className={classes.twoEpisodeFreeMob}
								onClick={() => navigate('/')}
								style={{cursor: 'pointer', border: '0px'}}
							>
								<p>Try 2 episodes</p>
								<h3>FOR FREE</h3>
							</button>
						) : null}
						<ul>
							<NavLink to="/">
								<li>
									<img src={homeIcon} alt="homeIcon"/> Home
								</li>
							</NavLink>
							<NavLink to="/allseries">
								<li>
									<img src={barChart} alt="barChart"/> Turkish Series
								</li>
							</NavLink>
							<NavLink to="/allmovies">
								<li>
									<img src={barMovieChart} alt="barMovieChart"/> Turkish Movies
								</li>
							</NavLink>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: '20px',
									fontFamily: 'Plus Jakarta Sans',
									fontSize: '20px',
									fontWeight: '700',
									color: 'white',
									padding: '24px 0px',
									borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
									opacity: '0.6',
								}}
							>
								<img src={profileLogin} alt="profileLogin"/>
								<p>
									{currentUser ? (
										<NavLink to="/edit-profile" style={{color: 'white'}}>
											Profile
										</NavLink>
									) : (
										<NavLink to="/signin" style={{color: 'white'}}>
											Login
										</NavLink>
									)}
								</p>
							</div>
						</ul>
					</div>
				</div>
			</div>

			<div className={classes.headerInner}>
				<div className={classes.left}>
					<NavLink to="/">
						<img className={classes.logo} src={logo} alt="logo"/>
					</NavLink>
					<ul className={classes.ul}>
						<NavLink to="/">
							{' '}
							<li>Home</li>
						</NavLink>
						<NavLink to="/allseries">
							<li>Turkish Series</li>
						</NavLink>
						<NavLink to="/allmovies">
							<li>Turkish Movies</li>
						</NavLink>
					</ul>
					<form onSubmit={(e) => e.preventDefault()}>
						<input
							type="text"
							placeholder="Films, series, actors"
							ksdkj
							onChange={handleSearch}
						/>

						<div className={classes.searchDropDown}>
							{searchResults?.data.body.movies.map((movie) => (
								<div
									className={classes.searchMovieList}
									onClick={() => navigate(`/moviedetail/${movie.movie_id}`)}
								>
									<img
										src={`${process.env.REACT_APP_BASE_URL}/images/${movie.movie_image}`}
										alt="Movie"
									/>
									<div>
										<h3>{movie.movie_title}</h3>
										<p>
											<span>10</span> &nbsp; · &nbsp;{movie.movie_year}-...
										</p>
									</div>
								</div>
							))}
							{searchResults?.data.body.series.map((serie) => (
								<div
									className={classes.searchMovieList}
									onClick={() => navigate(`/seriesdetail/${serie.serie_id}`)}
								>
									<img
										src={`${process.env.REACT_APP_BASE_URL}/images/${serie.serie_image}`}
										alt="Movie"
									/>
									<div>
										<h3>{serie.serie_title}</h3>
										<p>
											<span>10</span> &nbsp; · &nbsp;{serie.serie_year}-...
										</p>
									</div>
								</div>
							))}
							{(searchResults?.data.body.movies.length > 0 ||
							  searchResults?.data.body.series.length > 0) && (
								 <div
									 className={classes.btnSearchAll}
									 onClick={() => navigate('/search', {state: query})}
								 >
									 <button>
										 <span>See all results</span>
									 </button>
								 </div>
							 )}
						</div>
						{/* <div className={classes.searchDropDown}>
             
              </div> */}

						<span>
              <img
	              onClick={() => navigate('/search', {state: query})}
	              className={classes.filter}
	              src={SlidersHorizontal}
	              alt="SlidersHorizontal"
	              style={{marginRight: '10px'}}
              />

							{/* <Link to="/search"> */}
							<img
								onClick={() => navigate('/search', {state: query})}
								className={classes.search}
								src={MagnifyingGlass}
								alt="MagnifyingGlass"
							/>
							{/* </Link> */}
            </span>

						{/* for mobile search  */}
						<div className={classes.mobileSearch}>
							<img
								style={{marginRight: '20px', height: '28px'}}
								onClick={showMobileSearchHanlder}
								className={classes.mobileSearchIcon}
								src={MagnifyingGlass}
								alt="MagnifyingGlass"
							/>

							{setShowMobileSearchList && (
								<div className={classes.mobileForm}>
									<form>
										<input
											type="text"
											placeholder="Films, series, actors"
											onChange={handleSearch}
										/>
										<Link to="/allmovies">
											<img
												className={classes.filter}
												src={SlidersHorizontal}
												alt="SlidersHorizontal"
											/>
										</Link>
									</form>
									{showMobileSearch && (
										<div className={classes.searchDropDownMobile}>
											{searchResults?.data.body.movies.map((movie) => (
												<div
													className={classes.searchMovieListMobile}
													onClick={() =>
														navigate(`/moviedetail/${movie.movie_id}`)
													}
												>
													<img
														src={`${process.env.REACT_APP_BASE_URL}/images/${movie.movie_image}`}
														alt="Movie"
													/>
													<div>
														<h3>{movie.movie_title}</h3>
														<p>
															<span>10</span> &nbsp; · &nbsp;{movie.movie_year}
															-...
														</p>
													</div>
												</div>
											))}
											{searchResults?.data.body.series.map((serie) => (
												<div
													className={classes.searchMovieListMobile}
													onClick={() =>
														navigate(`/seriesdetail/${serie.serie_id}`)
													}
												>
													<img
														src={`${process.env.REACT_APP_BASE_URL}/images/${serie.serie_image}`}
														alt="Movie"
													/>
													<div>
														<h3>{serie.serie_title}</h3>
														<p>
															<span>10</span> &nbsp; · &nbsp;{serie.serie_year}
															-...
														</p>
													</div>
												</div>
											))}
											{(searchResults?.data.body.movies.length > 0 ||
											  searchResults?.data.body.series.length > 0) && (
												 <div
													 className={classes.btnSearchAll}
													 onClick={() => navigate('/search', {state: query})}
												 >
													 <button>
														 <span>See all results</span>
													 </button>
												 </div>
											 )}
										</div>
									)}
								</div>
							)}
						</div>
						{/* mobile search end  */}
						{/* notification for mobile screen start  */}
						{!currentUser?.body.user.user_status === false && (
							<div
								className={classes.notification}
								onClick={showNotifHandlerToggle}
							>
                <span>
                  {/* <img src={notifyy} alt="notify" /> */}
	                <img
		                className={classes.menuIcon}
		                src={notifyy}
		                alt="notifyy"
	                />
                </span>
								{showNotification && (
									<div className={classes.notificationDropDown}>
										<div className={classes.notificationHeader}>
											<img
												className={classes.menuIcon}
												src={notifyy}
												alt="notifyy"
											/>
											<h3>Notification</h3>
										</div>
										{notification?.data.body.notifications.map((item) => (
											<a
												onClick={() =>
													navigate(`/seriesdetail/${item.item_id}`)
												}
											>
												<div className={classes.lefttt}>
													<h3>{item.content}</h3>
													<p>
														{item.title}
														{/* · Season 2 Episode 1 */}
													</p>
												</div>
												<span className={classes.dot}></span>
											</a>
										))}
									</div>
								)}
							</div>
						)}
						<img
							className={classes.menuIcon}
							onClick={openMobileMenu}
							src={menuIcon}
							alt="menuIcon"
						/>
					</form>
				</div>
				<div className={classes.right}>
					{!currentUser ? (
						// history?.data.body.history.length < 2 &&
						//  currentUser.body.user.subscription === null
						<button
							className={classes.twoEpisodeFree}
							onClick={() => navigate('/signin')}
							style={{border: '0px'}}
						>
							<p>Try 2 episodes</p>
							<h3>FOR FREE</h3>
						</button>
					) : currentUser &&
					    currentUser?.body.user.user_status === false &&
					    history?.data.body.history.length < 2 ? (
						<button
							className={classes.twoEpisodeFree}
							onClick={() => navigate('/')}
							style={{cursor: 'pointer', border: '0px'}}
						>
							<p>Try 2 episodes</p>
							<h3>FOR FREE</h3>
						</button>
					) : null}

					<div className={classes.profilePic}>
						<p>
							{currentUser ? (
								currentUser.body.user.user_name
							) : (
								<NavLink to="/signin" style={{color: 'white'}}>
									Login
								</NavLink>
							)}
						</p>

						{/* notification for desktop */}
						{!currentUser?.body.user.user_status === false && (
							<div
								className={classes.notification}
								onClick={showNotifHandlerToggle}
							>
                <span>
                  {/* <img src={notifyy} alt="notify" /> */}
	                <img
		                className={classes.menuIcon}
		                src={notifyy}
		                alt="notifyy"
	                />
                </span>
								{showNotification && (
									<div className={classes.notificationDropDown}>
										<div className={classes.notificationHeader}>
											<img
												className={classes.menuIcon}
												src={notifyy}
												alt="notifyy"
											/>
											<h3>Notification</h3>
										</div>
										{notification?.data.body.notifications.map((item) => (
											<a
												onClick={() =>
													navigate(`/seriesdetail/${item.item_id}`)
												}
											>
												<div className={classes.lefttt}>
													<h3>{item.content}</h3>
													<p>
														{item.title}
														{/* · Season 2 Episode 1 */}
													</p>
												</div>
												<span className={classes.dot}></span>
											</a>
										))}
									</div>
								)}
							</div>
						)}

						<div className={classes.profile}>
              <span onClick={showProfileHandlerToggle}>
                <img src={User} alt="user"/>
              </span>
							{showProfileMenu && (
								<div className={classes.dropDown}>
									<img className={classes.arrowTop} src={pArrow} alt="pArrow"/>
									<Link to="/signin" onClick={logoutHandler}>
										<div style={{display: 'flex', flexDirection: 'row'}}>
											{' '}
											<img
												src={
													JSON.parse(localStorage.getItem('user')) == null
														? User
														: SignOut
												}
												alt="SignOut"
												style={{width: '25px', height: '25px'}}
											/>
											<h3>
												{' '}
												{JSON.parse(localStorage.getItem('user')) == null
													? 'Login'
													: 'Log Out'}{' '}
											</h3>
										</div>
									</Link>
									{JSON.parse(localStorage.getItem('user')) && (
										<Link to="/history" onClick={showProfileHandlerToggle}>
											<img src={clock} alt="clock"/>
											<h3>History</h3>
										</Link>
									)}

									{JSON.parse(localStorage.getItem('user')) && (
										<Link to="/watch-later" onClick={showProfileHandlerToggle}>
											<img src={watchLater} alt="watchLater"/>
											<h3>Watch Later</h3>
										</Link>
									)}

									{/* {!currentUser ? ( */}
									{/* <Link to="/signin" onClick={showProfileHandlerToggle}>
                      <img src={PencilSimple} alt="PencilSimple" />{" "}
                      <h3>Edit profile</h3>
                    </Link> */}
									{/* ) : ( */}
									{JSON.parse(localStorage.getItem('user')) && (
										<Link to="/edit-profile" onClick={showProfileHandlerToggle}>
											<img src={PencilSimple} alt="PencilSimple"/>{' '}
											<h3>Edit profile</h3>
										</Link>
									)}

									{/* )} */}

									{/* <Link to="/edit-profile" onClick={showProfileHandlerToggle}>
                    <img src={PencilSimple} alt="PencilSimple" />
                    <h3>Edit profile</h3>
                  </Link> */}
									{JSON.parse(localStorage.getItem('user')) && (
										<Link to="/subscription" onClick={showProfileHandlerToggle}>
											<img src={MonitorPlay} alt="MonitorPlay"/>
											<h3>Subscription</h3>
										</Link>
									)}

									<Link to="/contact" onClick={showProfileHandlerToggle}>
										<img src={ChatCircleDots} alt="ChatCircleDots"/>
										<h3>Contact Us</h3>
									</Link>

									<Link to="/faq" onClick={showProfileHandlerToggle}>
										<img src={info} alt="info"/>
										<h3>FAQs</h3>
									</Link>

									<Link to="/privacy-policy" onClick={showProfileHandlerToggle}>
										<img src={ShieldCheck} alt="ShieldCheck"/>
										<h3>Privacy Policy</h3>
									</Link>

									<Link to="/term-condition" onClick={showProfileHandlerToggle}>
										<img src={ListBullets} alt="ListBullets"/>
										<h3>Terms & Conditions</h3>
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Header;
