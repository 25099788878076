import React, { useContext } from "react";
import classes from "./MovieCard.module.css";
import star from "./../../img/star.svg";
import save from "./../../img/save.svg";
import { useNavigate } from "react-router";
import { makeReqest } from "../../axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import filledWatchLaterIcon from "./../../img/BookmarkSimple.svg";
import { AuthContext } from "../../context/authContext";

const MovieCard = ({ movie, history }) => {
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const addToWatchList = useMutation({
    mutationFn: () => {
      return makeReqest.post(`wishlist/movies/${movie?.movie_id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["loadMovies"]);
      queryClient.invalidateQueries(["userHistory"]);
      queryClient.invalidateQueries(["watchList"]);
    },
  });
  console.log(movie?.is_wishlist);
  return (
    <div
      className={classes.cardUiTop}
      onClick={() => navigate(`/moviedetail/${movie?.movie_id}#details`)}
    >
      <div className={classes.headerCard}>
        <div className={classes.rating}>
          <div>
            <img className={classes.star} src={star} alt="star" />
            <h3>10</h3>
          </div>
          {currentUser?.body.user.user_status && (
            <img
              style={{ zIndex: "1000" }}
              className={classes.save}
              src={movie?.is_wishlist ? filledWatchLaterIcon : save}
              alt="save"
              onClick={(e) => {
                e.stopPropagation();
                addToWatchList.mutate();
              }}
            />
          )}

          {/* {currentUser?.body.user.user_status && (
             <img
             className={classes.bookmark}
             src={movie?.is_notification_on ? NotificationFilled : bookmark}
             // src={bookmark}
             alt="bookmark"
             onClick={(e) => {
               e.stopPropagation();
               addToNotification.mutate();
             }}
           />
          )} */}
        </div>
        <img
          className={classes.imgDefault}
          src={`${process.env.REACT_APP_BASE_URL}/images/${movie?.movie_image}`}
          alt="top series"
          // style={{ width: "400px", height: "560px" }}
        />

        <div className={classes.hoverCard}>
          <div className={classes.details}>
            <h4>{movie?.movie_title}</h4>
            {/* <CharacterLimit text={movie?.movie_description} limit={190} /> */}
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <h3>{movie?.movie_title}</h3>
        <p>
          {movie?.movie_year}{" "}
          {/* {!movie?.genres?.length == 0 && (
            <span
              style={{ position: "relative", top: "-4px", marginLeft: "10px" }}
            >
              .
            </span>
          )}
          <span style={{ marginLeft: "10px" }}>
            {movie?.genres &&
              movie?.genres.map(
                (genre, index) =>
                  `${genre.genre_title}${
                    movie?.genres?.length - 1 !== index ? "," : ""
                  }`
              )}
          </span> */}
        </p>
      </div>
    </div>
  );
};
export default MovieCard;
