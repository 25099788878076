import React from "react";
import classes from "./NoResult.module.css";
import Info from "./../../img/InfoSearch.svg";

const NoResult = ({ query }) => {
  return (
    <div className={classes.noResultTop}>
      {/* <h2>Search results for: {query}</h2> */}
      <div>
        <img src={Info} alt="Info" />
        <h3>No Results</h3>
      </div>
    </div>
  );
};

export default NoResult;
