import React, { useContext, useState } from "react";
import classes from "./ClientReviews.module.css";
import star from "./../../../img/star.svg";
import replyImg from "./../../../img/Reply.svg";
import trash from "./../../../img/Trash.svg";
import DateFormater from "../../DateFormater";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeReqest } from "../../../axios";
import { AuthContext } from "../../../context/authContext";
import staticReviewImg from "./../../../StaticPic/dummyReviewImg.jpeg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClientReviewsOnMovie = ({ moviesReviews }) => {
  const [showForm, setIsShowForm] = useState(0);
  const currentUser = useContext(AuthContext);
  const [reviewReply, setReviewReply] = useState({
    user_name: JSON.parse(localStorage.getItem("user"))?.body.user.user,
    reply: "",
    fk_user_id: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
  });
  const queryClient = useQueryClient();

  const showFormHandler = (id) => {
    setIsShowForm(id);
  };

  const replyReview = useMutation({
    mutationFn: (id) => {
      return makeReqest.post(`movies/reviews/${id}`, reviewReply);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["moviesDetail"]);
      setReviewReply({
        user_name: "",
        reply: "",
        fk_user_id: JSON.parse(localStorage.getItem("user"))?.body.user.user_id,
      });
      setIsShowForm(0);
      toast.success("Successfully add review", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movies-error",
      });
    },
  });
  const deleteReply = useMutation({
    mutationFn: (id) => {
      return makeReqest.delete(`/movies/reviews/reply/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["moviesDetail"] });
      queryClient.invalidateQueries(["watch-movie"]);
    },
  });
  const deleteReview = useMutation({
    mutationFn: (id) => {
      return makeReqest.delete(`movies/reviews/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["moviesDetail"] });
      queryClient.invalidateQueries(["watch-movie"]);
    },
  });
  console.log(JSON.parse(localStorage.getItem("user"))?.body.user.user_id);
  return (
    <>
      <div className={classes.clientReviewsTop}>
        <div className={classes.reviewsCount}>
          <h2>Reviews</h2>
          <span>{moviesReviews ? moviesReviews.length : 0}</span>
        </div>
        {moviesReviews &&
          moviesReviews.map((review) => (
            <div className={classes.review} style={{ marginBottom: "30px" }}>
              <div className={classes.inner}>
                <div className={classes.headerReview}>
                  <div className={classes.left}>
                    <img
                      src={
                        !review?.reviewedBy?.image_url
                          ? staticReviewImg
                          : `${process.env.REACT_APP_BASE_URL}/images/${review?.reviewedBy?.image_url}`
                      }
                      alt="reviewImg"
                    />
                    <div className={classes.userData}>
                      <h4>{review.user}</h4>
                      <DateFormater date={review.published} />
                    </div>
                  </div>
                  <div className={classes.totalReview}>
                    <img src={star} alt="star" />
                    <p>{review.rating}</p>
                    <p></p>
                  </div>
                </div>
                <p className={classes.reviewMessage}>{review.content}</p>
                {/* {console.log("review id",review?.reviews_replies[0].id)} */}
                {currentUser.currentUser && (
                  <div className={classes.replyDeleteReview}>
                    <span onClick={() => showFormHandler(review.id)}>
                      <img src={replyImg} alt="reply" /> <p>Reply</p>
                    </span>
                    {review.fk_user_id ==
                      JSON.parse(localStorage.getItem("user"))?.body.user
                        .user_id && (
                      <span
                        onClick={() => deleteReview.mutate(review.id)}
                        // onClick={() => deleteReply.mutate(review?.reviews_replies[0]?.id)}
                      >
                        <img src={trash} alt="trash" /> <p>Delete</p>
                      </span>
                    )}
                  </div>
                )}
                <div className={classes.replies}>
                  {/* replies here 1 */}
                  {review.reviews_replies.length > 0 &&
                    review.reviews_replies.map((reply) => (
                      <div className={classes.repliesInner} key={reply.id}>
                        <div className={classes.headerReview}>
                          <div className={classes.left}>
                            <img
                              src={
                                !reply?.repliedBy?.image_url
                                  ? staticReviewImg
                                  : `${process.env.REACT_APP_BASE_URL}/images/${reply?.repliedBy?.image_url}`
                              }
                              alt="reviewImg"
                            />
                            <div className={classes.userData}>
                              <h4>{reply.user_name}</h4>
                              <p>11 Aug 2022</p>
                            </div>
                          </div>
                        </div>
                        <p className={classes.reviewMessage}>{reply.reply}</p>
                        <div className={classes.replyDeleteReview}>
                          <span onClick={showFormHandler}>
                            <img src={replyImg} alt="reply" /> <p>Reply</p>
                          </span>
                          {reply.fk_user_id ==
                            JSON.parse(localStorage.getItem("user"))?.body.user
                              .user_id && (
                            <span
                              onClick={() => deleteReply.mutate(reply.id)}
                              // onClick={() => deleteReply.mutate(review?.reviews_replies[0]?.id)}
                            >
                              <img src={trash} alt="trash" /> <p>Delete</p>
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  {review.id === showForm && (
                    <form>
                      <textarea
                        placeholder="Reply"
                        onChange={(e) =>
                          setReviewReply((prev) => ({
                            ...prev,
                            reply: e.target.value,
                          }))
                        }
                      />
                      <div className={classes.btns}>
                        <button
                          className={classes.reply}
                          onClick={(e) => {
                            e.preventDefault();
                            replyReview.mutate(review.id);
                          }}
                        >
                          Reply
                        </button>
                        <button
                          className={classes.cancel}
                          onClick={(e) => {
                            e.preventDefault();
                            setIsShowForm((prev) => !prev);
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          ))}
        <ToastContainer limit={1} />
      </div>

      {/* <div className={classes.ClientReviewsOnMovieTop}>
        <div className={classes.review}>
          <div className={classes.inner}>
            <div className={classes.headerReview}>
              <div className={classes.left}>
                <img src={reviewImg} alt="reviewImg" />
                <div className={classes.userData}>
                  <h4>@grandfatheroak76</h4>
                  <p>11 Aug 2022</p>
                </div>
              </div>
              <div className={classes.totalReview}>
                <img src={star} alt="star" />
                <p>10</p>
              </div>
            </div>
            <p className={classes.reviewMessage}>
              This show was wonderful. It was heartfelt. It was as if I was
              sharing their story as I watched
            </p>

            <div className={classes.replyDeleteReview}>
              <span onClick={showFormNoReviewHandler}>
                <img src={reply} alt="reply" /> <p>Reply</p>
              </span>
              <span>
                <img src={trash} alt="trash" /> <p>Delete</p>
              </span>
            </div>
            {norReplies && (
              <div className={classes.replies}>
                {showFormNoReview && (
                  <form>
                    <textarea
                      placeholder="Reply"

                    />
                    <div className={classes.btns}>
                      <button className={classes.reply} >Reply</button>
                      <button className={classes.cancel}>
                        <span>Cancel</span>
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ClientReviewsOnMovie;
