import { useMutation } from "@tanstack/react-query";
import classes from "./SubscriptionCardUI.module.css";
import React, { useContext } from "react";
import { makeReqest } from "../../../axios";
import { AuthContext } from "../../../context/authContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SubscriptionCardUi = ({ subscriptionPkg }) => {
  const { currentUser } = useContext(AuthContext);
  const changePackage = useMutation({
    mutationFn: (id) => {
      return makeReqest.post(
        `${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/change/${id}`
      );
    },
    onSuccess: (data) => {
      // queryClient.invalidateQueries(["movies"]);
      toast.success("Subscription Change successfully", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movie-success",
      });
    },
    onError: (error) => {
      toast.error(error.response.data.message || error.response.data.error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: "add-movies-error",
      });
    },
  });
  const handleClick = (id) => {
    changePackage.mutate(id);
  };
  return (
    <div className={classes.cardTop}>
      <h2>{subscriptionPkg.title}</h2>
      <h2>
        ${subscriptionPkg.amount}{" "}
        <span>
          /{subscriptionPkg.duration}{" "}
          {subscriptionPkg.type === "days"
            ? "days"
            : subscriptionPkg.type === "monthly"
            ? "months"
            : subscriptionPkg.type === "yearly"
            ? "years"
            : null}
        </span>
      </h2>
      <ul>
        <li>No Advertisement</li>
        <li>English Subtitles</li>
        <li>Unlimited Devices</li>
        <li>HD Resolution</li>
        <li>New Episodes Notifications</li>
      </ul>
      <button
        onClick={() => {
          handleClick(subscriptionPkg?.id);
        }}
      >
        <span>Get</span>
      </button>
      <ToastContainer limit={1} />
    </div>
  );
};

export default SubscriptionCardUi;
