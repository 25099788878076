import classes from './MoviePlayer.module.css';
import React, {useEffect} from 'react';
import save from '../../../img/save.svg';
import info from '../../../img/bookmark.svg';
import SocialShare from '../../SocialShare/SocialShare';
import Recommended from '../../RecommendedMovie/Recommended';
import {useNavigate, useParams} from 'react-router';
import SeriesEpisodes from '../seriesEpisodes/SeriesEpisodes';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import Reviews from '../../Reviews/Reviews';
import filledWatchLaterIcon from './../../../img/BookmarkSimple.svg';
import NotificationFilled from './../../../img/notificationFilled.svg';
import axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {useState} from 'react';
import {toast} from 'react-toastify';

const EpisodePlayer = () => {
	let {id} = useParams();
	const [seriesData, setSeriesData] = useState(null);
	const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
	const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(0);
	const queryClient = useQueryClient();
	const navigate = useNavigate();


	const {data: episode,error, isLoading} = useQuery({
		queryKey: ['episode', id],
		queryFn: () => {
			return makeReqest.get(`episodes/${id}`);
		},
		// onSuccess: () => {
		//   queryClient.invalidateQueries(["serie"]);
		// },
		retry: (error) => {
			navigate("/choosepayment");
			return false;
		},
		onError: (error) => {
			toast.error(error.response.data.message || error.response.data.error, {
				position: 'bottom-left',
				autoClose: 15000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				toastId: 'add-movies-error',
			});
		},
	});


	const {data} = useQuery({
		queryKey: ['serie'],
		queryFn: () => {
			return axios.post(
				`${process.env.REACT_APP_BASE_URL}/api/series/${episode?.data.body.episode.episode_serie}`,
				{userId: JSON.parse(localStorage.getItem('user'))?.body.user.user_id}
			);
		},
		enabled: !!episode?.data.body.episode.episode_serie,
		onSuccess: () => {

		}
	});

	const addToWatchList = useMutation({
		mutationFn: () => {
			return makeReqest.post(
				`wishlist/series/${data?.data.body.series?.serie_id}`
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['serie']);
		},
	});

	const addToNotification = useMutation({
		mutationFn: () => {
			return makeReqest.get(
				`user_notifications/${data?.data.body.series?.serie_id}`
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['serie']);
		},
	});

	useEffect(() => {
		const episodeContainer = document.getElementById('episodeIframe');
		const iframe = episodeContainer?.firstElementChild;
		iframe?.setAttribute('allowfullscreen', 'allowfullscreen');
		iframe?.setAttribute('mozallowfullscreen', 'mozallowfullscreen');
		iframe?.setAttribute('msallowfullscreen', 'msallowfullscreen');
		iframe?.setAttribute('oallowfullscreen', 'oallowfullscreen');
		iframe?.setAttribute('webkitallowfullscreen', 'webkitallowfullscreen');
		// console.log(iframe);
	}, []);

	useEffect(() => {
		// Assuming data?.data?.body.series and id are correctly received
		const foundEpisode = findEpisodeInSeasons(data?.data?.body.series, id);
		// console.log(foundEpisode);
		if (foundEpisode) {
			setSelectedSeasonIndex(foundEpisode.seasonIndex);
			setSelectedEpisodeIndex(foundEpisode.episodeIndex);
		}
	}, [id]);

	const findEpisodeInSeasons = (seriesData, episodeId) => {
		for (
			let seasonIndex = 0;
			seasonIndex < seriesData?.seasons.length;
			seasonIndex++
		) {
			const season = seriesData.seasons[seasonIndex];
			const episodeIndex = season.episodes.findIndex(
				(episode) => episode.episode_id == episodeId
			);

			if (episodeIndex !== -1) {
				return {seasonIndex, episodeIndex};
			}
		}
		return null;
	};

	const handleNextEpisode = () => {
		if (
			selectedEpisodeIndex <
			data?.data?.body.series.seasons[selectedSeasonIndex].episodes.length - 1
		) {
			setSelectedEpisodeIndex((next) => next + 1);
			const nextEpisodeId =
				      data?.data?.body.series.seasons[selectedSeasonIndex].episodes[
				      selectedEpisodeIndex + 1
					      ].episode_id;
			// console.log("selectedEpisodeId",nextEpisodeId);
			navigate(`/watchEpisodes/${nextEpisodeId}`);
		}
	};

	const handlePreviousEpisode = () => {
		if (selectedEpisodeIndex > 0) {
			setSelectedEpisodeIndex((prev) => prev - 1);
			const prevEpisodeId =
				      data?.data?.body.series.seasons[selectedSeasonIndex].episodes[
				      selectedEpisodeIndex - 1
					      ].episode_id;
			// console.log("selectedEpisodeId",prevEpisodeId);
			navigate(`/watchEpisodes/${prevEpisodeId}`);
		}
	};

	// console.log(selectedEpisodeIndex);
	return (
		<>
			{isLoading ? (
				<Spinner/>
			) : (
				<div>
					<div className={classes.moviePlayerTop}>
						<div className={classes.headerPlayer}>
							<>
								<button
									className={classes.prevEpisode}
									// onClick={() => navigate(`/watchEpisodes/${--id}`)}
									onClick={handlePreviousEpisode}
								>
									<span>Previous episode</span>
								</button>
								<div className={classes.seasonName}>
									<img
										className={classes.bookmark}
										src={
											data?.data.body.series?.is_notification_on
												? NotificationFilled
												: info
										}
										// src={bookmark}
										alt="bookmark"
										onClick={(e) => {
											e.stopPropagation();
											addToNotification.mutate();
										}}
										style={{cursor: 'pointer'}}
									/>

									<div>
										<h2>{data?.data.body.series.serie_title}</h2>
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												gap: '10px',
											}}
										>
											{' '}
											<p>{episode?.data.body.episode.season.title}</p>
											<p>{episode?.data.body.episode.episode_title}</p>
										</div>
									</div>
									<img
										src={
											data?.data.body.series?.is_wishlist
												? filledWatchLaterIcon
												: save
										}
										alt="save"
										onClick={(e) => {
											e.stopPropagation();
											addToWatchList.mutate();
										}}
										style={{cursor: 'pointer'}}
									/>
								</div>
								<button
									className={classes.nextEpisode}
									// onClick={() => navigate(`/watchEpisodes/${++id}`)}
									onClick={handleNextEpisode}
								>
									<span>Next episode</span>
								</button>
							</>
						</div>
						<div
							id="episodeIframe"
							className={classes.videoPlayer}
							dangerouslySetInnerHTML={{
								__html: episode?.data.body.episode.episode_link,
							}}
						>
							{/* <iframe
            title="myFrame"
            src={''}
            width="100%"
            height="500"
            frameborder="0"
            scrolling="no"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
          ></iframe> */}
						</div>
						<div className={classes.headerPlayer2}>
							<>
								<button className={classes.prevEpisodeTopBtn}>
									<span>Previous episode</span>
								</button>

								<button className={classes.nextEpisodeTopBtn}>
									<span>Next episode</span>
								</button>
							</>
						</div>
						<SeriesEpisodes
							canPlay={data?.data.body.canPlay}
							seasons={data?.data.body.series.seasons}
						/>
						<SocialShare/>

						<Reviews
							seriesId={data?.data.body.series.serie_id}
							reviews={data?.data.body.series.series_reviews}
						/>
					</div>
					<Recommended/>
				</div>
			)}
		</>
	);
};

export default EpisodePlayer;
