import React, { useContext } from "react";
import classes from "./PaymentDetail.module.css";
import logo from "./../../../img/Logo.svg";
import add from "./../../../img/add.svg";
import engicon from "./../../../img/engIcon.svg";
import repeat from "./../../../img/Repeat.svg";
import hd from "./../../../img/hd.svg";
import loginGif from "./../../../img/loginGif.gif";
import BellRinging from "./../../../img/BellRinging.svg";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../../axios";
const PaymentDetail = () => {
  const currentUser = useContext(AuthContext);
  const navigate = useNavigate();
  const { data: history } = useQuery({
    queryKey: ["userHistory"],
    queryFn: () => {
      return makeReqest.get(`history`);
    },
  });
  // console.log('user',currentUser.currentUser);
  return (
    <div className={classes.detailTop}>
      {!currentUser ? (
        <div
          className={classes.freeTwoEpisode}
          onClick={() => navigate("/signin")}
        >
          <p>Try 2 episodes</p>
          <h5>FOR FREE</h5>
        </div>
      ) : currentUser &&
        currentUser.currentUser?.body.user.user_status === false &&
        history?.data.body.history.length < 2 ? (
        <div className={classes.freeTwoEpisode} onClick={() => navigate("/")}>
          <p>Try 2 episodes</p>
          <h5>FOR FREE</h5>
        </div>
      ) : null}

      <div className={classes.inner}>
        <img src={loginGif} alt="loginGif" className={classes.loginGif} />
        <img className={classes.logo} src={logo} alt="logo" />
        <h2 className={classes.logoTxt}>WatchTurks </h2>
        <h1>
          All Turkish Series and Movies with English subtitles in one place!{" "}
        </h1>
        <p>
          Watch whatever & wherever you want. Recommendations just for you.
          Change or cancel your plan anytime.
        </p>
        <div className={classes.offerDetails}>
          <div className={classes.card}>
            <img src={add} alt="add" />
            <p>No Advertisement</p>
          </div>
          <div className={classes.card}>
            <img src={engicon} alt="engicon" />
            <p>English Subtitles</p>
          </div>
          <div className={classes.card}>
            <img src={repeat} alt="repeat" />
            <p>Unlimited Devices</p>
          </div>
          <div className={classes.card}>
            <img src={hd} alt="hd" />
            <p>
              HD <br />
              Resolution
            </p>
          </div>
          <div className={classes.card}>
            <img src={BellRinging} alt="BellRinging" />
            <p>New Episodes Notifications</p>
          </div>
        </div>

        <Link className={classes.choosePlan} to="/choosepayment">
          Choose a plan
        </Link>
      </div>
    </div>
  );
};

export default PaymentDetail;
