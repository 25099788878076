import React from "react";
import classes from "./CreateForm.module.css";
import googleLogo from "./../../img/googleLogo.svg";
import { useState } from "react";
import eyePass from "./../../img/Eyepassword.svg";
import eyeTxt from "./../../img/Eyetxt.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateForm = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [checkboxIsChecked, setCheckboxIsChecked] = useState(false);
  const [userInputs, setUserInputs] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
  });
  const navigate = useNavigate();
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const userSignUp = useMutation({
    mutationFn: () => {
      return makeReqest.post(
        `${process.env.REACT_APP_BASE_URL}/api/users/signup`,
        userInputs
      );
    },
    onSuccess: () => {
      setTimeout(() => {
        navigate("/signin");
      }, 2500);
      toast.success("Successfully registered", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        delay: 50,
      });
    },
    onError: (err) => {
      toast.error(err.response.data.error, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        delay: 50,
      });
    },
  });

  const handleChange = (e) => {
    setUserInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  function handleGoogleSignIn() {
    const googleAuthUrl =
      "https://lts.watchturks.tv/server/api/users/signin/google";

    // Open the Google authentication link in the current tab
    const authWindow = window.open(googleAuthUrl, "_self");
  }
  return (
    <div className={classes.CreateAcc}>
      <h1>Welcome to WatchTurks</h1>
      <p>Please create an account and try 2 episodes for free!</p>

      <button className={classes.googleBtn} onClick={handleGoogleSignIn}>
        <span>
          <img src={googleLogo} alt="googleLogo" />
          Sign up with Google
        </span>
      </button>
      <div className={classes.orRow}>
        <span className={classes.line}></span>
        <p>OR</p>
        <span className={classes.line}></span>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={classes.inputStroke}>
          <span>
            <input
              type="text"
              placeholder="Your Name"
              name="user_name"
              onChange={handleChange}
            />
          </span>
        </div>
        <div className={classes.inputStroke}>
          <span>
            <input
              type="email"
              placeholder="E-mail"
              name="user_email"
              onChange={handleChange}
            />
          </span>
        </div>
        <div className={classes.pass}>
          <span>
            <input
              type={passwordType}
              onChange={handleChange}
              name="user_password"
              placeholder="Password"
            />
            <div className="input-group-btn">
              <button type="button" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <img src={eyePass} alt="eyePass" />
                ) : (
                  <img src={eyeTxt} alt="eyeTxt" />
                )}
              </button>
            </div>
          </span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <input
            type="checkbox"
            onClick={() => setCheckboxIsChecked((prev) => !prev)}
            style={{ width: "20px", height: "20px" }}
          />
          <p>I agree with Terms & Conditions</p>
          {/* <input type="checkbox" />
          <span class="checkmark"></span> */}
        </div>
        <button
          disabled={!checkboxIsChecked}
          className={classes.createBtn}
          style={{
            background: checkboxIsChecked ? "" : "#fff",
            color: checkboxIsChecked ? "" : "gray",
          }}
          onClick={() => userSignUp.mutate()}
        >
          Create an account
        </button>
      </form>
      <span>
        Already have an account?{" "}
        <NavLink to="/signin" style={{ color: "white" }}>
          {" "}
          Sign In
        </NavLink>
      </span>
      <ToastContainer limit={1} />
    </div>
  );
};
export default CreateForm;
