import React, { useEffect } from "react";
import classes from "./Filter.module.css";
import dropdown from "./../../img/dropdown.svg";
import cross from "./../../img/Cross.svg";
import { useRef, useState } from "react";

const Filter = ({ handleSearch, handleFilterData, query }) => {
  const [showYearFilter, setIsShowYearFilter] = useState(false);
  const [showRatingFilter, setIsRatingGenreFilter] = useState(false);
  const [checkInputChecked, setIscheckInputChecked] = useState(false);
  const [showCross, setIsShowCross] = useState(false);
  const [showRatingCross, setIsRatingShowCross] = useState(false);
  const [showGenreCross, setIsGenreShowCross] = useState(false);
  const [years, setYears] = useState([]);
  const [genres, setGenres] = useState([]);
  const [rating, setRating] = useState(null);
  const ref = useRef([]);
  const ref2 = useRef([]);
  const ref3 = useRef([]);
  useEffect(() => handleFilterData({ year: years, genre: genres, rating }), [
    years,
    genres,
    rating,
  ]);
  const showYearFilterHandler = () => {
    setIsShowYearFilter((prevCheck) => !prevCheck);
    setIsRatingGenreFilter(false);
    setIsRatingShowCross(false);
    setIscheckInputChecked(false);
    setIsGenreShowCross(false);
  };
  //   const hideYearFilterHandler = () => {
  //     setIsShowYearFilter(false);
  //   };

  const showRatingFilterHandler = () => {
    setIsRatingGenreFilter((prevCheck) => !prevCheck);
    setIsShowYearFilter(false);
    setIsShowCross(false);
    setIscheckInputChecked(false);
    setIsGenreShowCross(false);
  };
  //   const hideRatingFilterHandler = () => {
  //     setIsRatingGenreFilter(false);
  //   };

  const showGenreFilterHandler = () => {
    setIscheckInputChecked((prevCheck) => !prevCheck);
    setIsShowYearFilter(false);
    setIsShowCross(false);
    setIsRatingShowCross(false);
    setIsRatingGenreFilter(false);
  };

  const checkboxvalue = (e) => {
    setIsShowCross(true);
    setIscheckInputChecked(false);
    const label = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setYears([...years, label]);
    } else {
      setYears(years.filter((l) => l !== label));
    }
  };

  const Unchecked = () => {
    // setIsShowYearFilter(false);
    setIsShowCross(false);
    // setIscheckInputChecked(false);

    for (let i = 0; i < ref.current.length; i++) {
      ref.current[i].checked = false;
    }
    setYears([]);
  };

  const checkboxvalueGenre = (e) => {
    setIsGenreShowCross(true);
    const label = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setGenres([...genres, label]);
    } else {
      setGenres(genres.filter((l) => l !== label));
    }
  };
  const checkboxUnCheckGenre = () => {
    setIsGenreShowCross(false);
    for (let i = 0; i < ref3.current.length; i++) {
      ref3.current[i].checked = false;
    }
    setGenres([]);
  };

  const checkboxvalueRating = (e) => {
    setIsRatingShowCross(true);
    const label = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setRating(label);
    } else {
      setRating(null);
    }
  };
  const checkboxUnCheck = () => {
    setIsRatingShowCross(false);
    for (let i = 0; i < ref2.current.length; i++) {
      ref2.current[i].checked = false;
    }
    setRating([]);
  };
  //   const Checked = () => {

  //       for (let i = 0; i < ref.current.length; i++) {

  //           ref.current[i].checked = true;
  //       }

  //   }

  return (
    <div className={classes.filterTop}>
      <form>
        <input
          type="text"
          placeholder="Series,actors"
          name="search"
          onChange={handleSearch}
          value={query}
        />
      </form>
      <div className={classes.filterData}>
        <div
          className={
            showYearFilter
              ? `${classes.byYear} ${classes.active}`
              : `${classes.byYear}`
          }
        >
          <p>Year</p>{" "}
          <img
            src={dropdown}
            className={classes.dropdownBtn}
            onClick={showYearFilterHandler}
            alt="dropdown"
          />
          {showCross && (
            <img
              className={classes.cross}
              onClick={Unchecked}
              src={cross}
              alt="cross"
            />
          )}
        </div>
        <div
          className={
            checkInputChecked
              ? `${classes.genre} ${classes.genreActive}`
              : `${classes.genre}`
          }
        >
          <p>Genre</p>{" "}
          <img
            src={dropdown}
            onClick={showGenreFilterHandler}
            className={classes.dropdownBtn}
            alt="dropdown"
          />
          {showGenreCross && (
            <img
              className={classes.cross}
              onClick={checkboxUnCheckGenre}
              src={cross}
              alt="cross"
            />
          )}
        </div>
        <div
          className={
            showRatingFilter
              ? `${classes.rating} ${classes.ratingActive}`
              : `${classes.rating}`
          }
        >
          <p>Rating</p>{" "}
          <img
            onClick={showRatingFilterHandler}
            className={classes.dropdownBtn}
            src={dropdown}
            alt="dropdown"
          />
          {showRatingCross && (
            <img
              className={classes.cross}
              onClick={checkboxUnCheck}
              src={cross}
              alt="cross"
            />
          )}
        </div>
      </div>
      <div className={classes.showData}>
        {showYearFilter && (
          <div className={classes.yeaName}>
            <div
              className={checkInputChecked ? `${classes.checkedActive}` : ``}
            >
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[0] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2023"
                />
              </label>
              <p>2023</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[1] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2022"
                />
              </label>
              <p>2022</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[2] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2021"
                />
              </label>
              <p>2021</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[3] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2016-2020"
                />
              </label>
              <p>2016-2020</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[4] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2011-2015"
                />
              </label>
              <p>2011-2015</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[5] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="2001-2010"
                />
              </label>
              <p>2001-2010</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[6] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="1991-2000"
                />
              </label>
              <p>1991-2000</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref.current[7] = element;
                  }}
                  onChange={checkboxvalue}
                  type="checkbox"
                  value="1970-1990"
                />
              </label>
              <p>1970-1990</p>
            </div>
          </div>
        )}
        {showRatingFilter && (
          <div className={classes.yeaName}>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[0] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="1"
                />
              </label>
              <p>1</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[1] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="2"
                />
              </label>
              <p>2</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[2] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="3"
                />
              </label>
              <p>3</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[3] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="4"
                />
              </label>
              <p>4</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[4] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="5"
                />
              </label>
              <p>5</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[5] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="6"
                />
              </label>
              <p>6</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[6] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="7"
                />
              </label>
              <p>7</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[7] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="8"
                />
              </label>
              <p>8</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[8] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="9"
                />
              </label>
              <p>9</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref2.current[9] = element;
                  }}
                  onChange={checkboxvalueRating}
                  type="checkbox"
                  value="10"
                />
              </label>
              <p>10</p>
            </div>
          </div>
        )}

        {checkInputChecked && (
          <div className={classes.yeaName}>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[0] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="1"
                />
              </label>
              <p>Action</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[1] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="2"
                />
              </label>
              <p>Adventure</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[2] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="6"
                />
              </label>
              <p>Comedy</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[3] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="4"
                />
              </label>
              <p>Drama</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[4] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="3"
                />
              </label>
              <p>Sci-Fi</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[5] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="5"
                />
              </label>
              <p>History</p>
            </div>
            <div>
              <label className={classes.container}>
                <input
                  ref={(element) => {
                    ref3.current[6] = element;
                  }}
                  onChange={checkboxvalueGenre}
                  type="checkbox"
                  value="8"
                />
              </label>
              <p>Romance</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
