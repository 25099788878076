import React, { useContext } from "react";
import classes from "./TermCondition.module.css";
import ListBullets from "./../../../img/ListBullets.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import { leftMenu } from "../ProfileMain";
const TermCondition = () => {
  const ctxTermCondition = useContext(leftMenu);

  const showSidebar = () => {
    ctxTermCondition();
  };
  return (
    <div className={classes.termConditionTop}>
      <div className={classes.termHeader}>
        <img
          className={classes.CaretLeft}
          onClick={showSidebar}
          src={CaretLeft}
          alt="CaretLeft"
        />
        <img
          className={classes.ListBullets}
          src={ListBullets}
          alt="ListBullets"
        />
        <h1>Terms & Conditions</h1>
      </div>
      <p className={classes.mb16}>
        Under Section 107 of the Copyright Act 1976, allowance is made for "fair
        use" for purposes such as criticism, commenting, news reporting,
        teaching, scholarship, and research. Fair use is a use permitted by
        copyright statute that might otherwise be infringing. Non- profit,
        educational or personal use tips the balance in favour of fair use.
        <br /> <br />
        We do not own any videos! All rights belong to the owner of the videos.
        The owners of videos can contact us to remove the content if you think
        that it is not allowed.
      </p>
      <h2>Subscription & Payments</h2>
      <p className={classes.mb16}>
        We offer a range of subscription plans tailored to suit your needs.
      </p>
      <h2>We Have Following Plans</h2>
      <p className={classes.mb16}>
        - Bronze Plan: It allows you to watch all series and movies on our
        website for 7 days. You can use unlimited devices and users.
        <br />
        - Silver Plan: It allows you to watch all series and movies on our
        website for 1 month. You can use unlimited devices and users.
        <br />
        - Golden Plan: It allows you to watch all series and movies on our
        website for 3 months. You can use unlimited devices and users.
        <br />- Platinum Plan: It allows you to watch all series and movies on
        our website for 1 year. You can use unlimited devices and users.
      </p>
      <h2>Payment Options:</h2>
      <p className={classes.mb16}>
        We offer flexible payment options to make the subscription process
        convenient for you.
        <br />
        <br />
        - Credit/Debit Cards: We accept all major credit and debit cards,
        ensuring a secure and straightforward payment experience.
        <br />- PayPal: For added convenience, we also support PayPal payments,
        allowing you to use your PayPal balance or linked payment methods.
      </p>
      <h2>Automatic Renewal:</h2>
      <p className={classes.mb24}>
        To avoid any disruptions in service, all subscriptions are set to
        automatically renew at the end of the billing cycle. You will receive a
        notification before each renewal, giving you the option to make changes
        or cancel if needed.
      </p>
      <h2>Managing Subscriptions:</h2>
      <p className={classes.mb24}>
        We've made it easy for you to manage your subscriptions. Through your
        account dashboard, you can upgrade or downgrade your plan, update
        payment methods, and view your billing history.
      </p>
      <h2>Cancellations and Refunds:</h2>
      <p className={classes.mb24}>
        You can cancel your subscription at any time before the next billing
        cycle. We do not offer refunds for partial usage during the billing
        period, but your subscription will remain active until the end of the
        paid period.
      </p>
      <br />

      <p className={classes.mb24}>
        For any questions or concerns regarding subscriptions and payments, our
        dedicated support team is here to assist you. Feel free to reach out to
        watchturks@gmail.com for prompt assistance.
      </p>
      <p className={classes.mb24}>
        Thank you for choosing Watch Turks. We value your trust and are
        committed to delivering the best services for your needs.
      </p>
    </div>
  );
};

export default TermCondition;
