import React, {useContext} from 'react';
import {useState} from 'react';
import SubscriptionCardUi from '../SubscriptionCardUi/SubscriptionCardUi';
import CaretLeft from './../../../img/CaretLeft.svg';
import classes from './Subscription.module.css';
import {leftMenu} from '../ProfileMain';
import {AuthContext} from '../../../context/authContext';
import {useMutation, useQuery} from '@tanstack/react-query';
import {makeReqest} from '../../../axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Subscription = () => {
	const {currentUser} = useContext(AuthContext);

	const [fixCard, setIsFixCard] = useState(false);

	const subCtx = useContext(leftMenu);
	// const currentUser = useContext(AuthContext)

	const showSidebar = () => {
		subCtx();
		setIsFixCard(true);
	};
	const {data} = useQuery({
		queryKey: ['packages'],
		queryFn: () => {
			return makeReqest.get('packages/');
		},
	});
	// console.log(currentUser?.body.user.plan_id);

	const cancelPlan = useMutation({
		mutationFn: () => {
			return makeReqest.post(
				`${process.env.REACT_APP_BASE_URL}/api/subscriptions/${currentUser?.body.user.user_id}/cancel`
			);
		},
		onSuccess: (data) => {
			toast.success(data?.data.body.message, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			window.location.reload();
		},
		onError: (err) => {
			return toast.error(err.response.data.error, {
				position: 'bottom-left',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				delay: 1,
			});
		},
	});
	return (
		<>
			<div className={classes.subscriptionTop}>
				{currentUser?.body.user.subscription && (
					<div className={classes.inner}>
						<img
							onClick={showSidebar}
							className={classes.CaretLeft}
							src={CaretLeft}
							alt="CaretLeft"
						/>
						<h1>Your plan</h1>
						<p>
							Watch all you want with English subs. Recommendations just for
							you. Change or cancel your plan anytime.
						</p>
						<div className={classes.currentPlan}>
							<div className={classes.planName}>
								<h2>{currentUser?.body.user.subscription}</h2>
								<h2>
									${currentUser?.body.user.price}{' '}
									<span>/{currentUser?.body.user.subscription_days} days</span>
								</h2>
							</div>
							<div className={classes.planDetails}>
								<ul className={fixCard === true ? `${classes.avtiveUl}` : null}>
									<li>
										{' '}
										<span></span> No Advertisement
									</li>
									<li>
										{' '}
										<span></span> English Subtitles
									</li>
									<li>
										<span></span> Unlimited Devices
									</li>
									<li>
										<span></span> HD Resolution
									</li>
									<li>
										<span></span> New Episodes Notifications
									</li>
								</ul>
							</div>
							<div className={classes.cancelSub}>
								<button
									className={classes.cancelBtn}
									onClick={() => cancelPlan.mutate()}
								>
									<span>Cancel</span>
								</button>
							</div>
						</div>

						<div className={classes.morePlan}>
							{data &&
							 data?.data.body.packages.map((pkg) =>
								 currentUser?.body.user.plan_id == pkg.id ? null : (
									 <SubscriptionCardUi subscriptionPkg={pkg} key={pkg.id}/>
								 )
							 )}
						</div>
					</div>
				)}

				{!currentUser?.body.user.subscription && (
					<div className={classes.inner}>
						<img
							className={classes.CaretLeft}
							onClick={showSidebar}
							src={CaretLeft}
							alt="CaretLeft"
						/>
						<h1>Choose your plan</h1>
						<p>
							Watch all you want with English subs. Recommendations just for
							you. Change or cancel your plan anytime.
						</p>

						<div className={classes.morePlanWSub}>
							{data &&
							 data?.data.body.packages.map((pkg) => (
								 <SubscriptionCardUi subscriptionPkg={pkg} key={pkg.id}/>
							 ))}
						</div>
					</div>
				)}
			</div>
			<ToastContainer limit={1}/>
		</>
	);
};
export default Subscription;
