import React from "react";
import { useState } from "react";
import Filter from "../Filter/Filter";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import NoResult from "./NoResult";
import classes from "./Search.module.css";
import SearchCard from "./SearchCard";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import axios from "axios";
import SeriesCard from "../AllSeries/SeriesCard";
import MovieCard from "../AllMovies/MovieCard";
import { useLocation } from "react-router";

const Search = () => {
  const location = useLocation();
  const [showSearch, setIsShowSearch] = useState(true);
  const [query, setQuery] = useState(location.state ? location.state : "");
  const [loadMore, setLoadMore] = useState(0);
  const [filteredData, setFilteredData] = useState();
  // const [filterData, setFilterData] = useState(null);
  // console.log(location.state);
  const showSearchHandler = () => {
    setIsShowSearch(true);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };
  const { data: searchResults } = useQuery({
    queryKey: ["search", query],
    queryFn: () => {
      return axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/home/search?q=${query}`
      );
    },
    enabled: !!query,
  });

  const { data: infinitData, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["loadMovies"],
    queryFn: ({ pageParam = 0 }) => {
      return makeReqest.get(`movies?page=${pageParam}`);
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.body.movies.length > 0) {
        return loadMore;
      } else {
        return undefined;
      }
    },
  });
  const { data: filteredMovies, isLoading: filterIsLoading } = useQuery({
    queryKey: ["filteredMovies", filteredData],
    queryFn: () => {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/movies/filters?page=${loadMore}`,
        filteredData
      );
    },
    enabled: !!filteredData,
  });
  console.log(filteredMovies);
  return (
    <div className={classes.allMoviesTop}>
      <div className={classes.allmoviesInner}>
        <Header />

        <div className={classes.moviesList}>
          <Filter
            query={query}
            handleSearch={handleSearch}
            handleFilterData={setFilteredData}
          />

          <>
            <h2>Search results for: {query}</h2>
            <div className={classes.row}>
              {(filteredMovies?.data.body.movies.length === 0 ||
                !filteredMovies) &&
                searchResults?.data.body.movies.map((movie) => (
                  <MovieCard movie={movie} />
                ))}
              {(filteredMovies?.data.body.movies.length === 0 ||
                !filteredMovies) &&
                searchResults?.data.body.series.map((serie) => (
                  <SeriesCard serie={serie} />
                ))}
            </div>
          </>

          {
            (searchResults?.data.body.movies.length <= 0 || !query) && (
              // (
              <NoResult query={query} />
            )
            // ) : (
            //   <div className={classes.row}>
            //     {filteredMovies?.data.body.movies.map((movie) => (
            //       <MovieCard movie={movie} />
            //     ))}
            //   </div>
            // )
          }
          {/* <button
            className={classes.loadMoreBtn}
            onClick={() => {
              fetchNextPage();
              setLoadMore((prev) => prev + 1);
            }}
            disabled={!hasNextPage}
          >
            <span>Show more</span>
          </button> */}
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default Search;
