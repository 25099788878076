import React, { useState } from "react";
import Filter from "../Filter/Filter";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import classes from "./AllSeries.module.css";
import SeriesCard from "./SeriesCard";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { makeReqest } from "../../axios";
import axios from "axios";
import Spinner from "../spinner/Spinner.jsx";
import NoResult from "../Search/NoResult";

const AllSeries = () => {
  const [loadMore, setLoadMore] = useState(1);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const {
    data: infinitData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["projects"],
    queryFn: ({ pageParam = 0 }) => {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/series?page=${pageParam}&limit=16&sortBy=movie_year&sortOrder=desc`,
        { userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id }
      );
    },
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.data.body.series.length > 0) {
        return loadMore;
      } else {
        return undefined;
      }
    },
  });
  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.value);
  };

  const { data: searchResults, isFetching } = useQuery({
    queryKey: ["searchSeries", query],
    queryFn: () => {
      return makeReqest.get(
        `${process.env.REACT_APP_BASE_URL}/api/series/search?q=${query}`
      );
    },
    enabled: !!query,
  });

  const { data: filteredSeries, isLoading: filterIsLoading } = useQuery({
    queryKey: ["filteredSeries", filteredData],
    queryFn: () => {
      return axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/series/filters?page=${loadMore}`,
        filteredData
      );
    },
  });
  console.log(isFetching);

  return (
    <div className={classes.allMoviesTop}>
      <div className={classes.allmoviesInner}>
        <Header />
        <div className={classes.moviesList}>
          <Filter
            handleSearch={handleSearch}
            handleFilterData={setFilteredData}
          />
          <h2>All Series</h2>
          <div className={classes.row}>
            {
              // (filteredSeries?.data.body.series.length === 0 ||
              //   !filteredSeries) &&
              isLoading || isFetching || filterIsLoading ? (
                <Spinner />
              ) : (
                infinitData?.pages.map((page) => {
                  const seriesToRender =
                    !query && filteredSeries?.data.body.series.length === 0
                      ? page
                      : query && filteredSeries?.data.body.series.length === 0
                      ? searchResults
                      : filteredSeries;
                  return (
                    seriesToRender &&
                    seriesToRender.data.body.series.map((serie) => (
                      <SeriesCard serie={serie} key={serie.serie_id} />
                    ))
                  );
                })
              )
            }
            {/* {filteredSeries?.data.body.series.map((serie) => (
              <SeriesCard serie={serie} />
            ))} */}
            {searchResults?.data.body.series.length <= 0 && query && (
              <NoResult />
            )}
          </div>
          {infinitData?.pages.length > 0 && !query && (
            <button
              className={classes.loadMoreBtn}
              onClick={() => {
                fetchNextPage();
                setLoadMore((prev) => prev + 1);
              }}
              disabled={!hasNextPage}
            >
              <span>Show more</span>
            </button>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
};
export default AllSeries;
