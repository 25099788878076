import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";

const queryClient = new QueryClient();

export const makeReqest = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/users/${
    JSON.parse(localStorage.getItem("user"))?.body.user.user_id
  }/`,
  withCredentials: false,
  mode: "cors",
});

makeReqest.interceptors.request.use((config) => {
  const token = Cookies.get("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers['X-User-Id'] = JSON.parse(localStorage.getItem("user"))?.body.user.user_id;
  }
  return config;
});

queryClient.setQueryDefaults({ queries: { makeReqest } });
