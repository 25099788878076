import React, { useContext, useState } from "react";
import AccordionItem from "./Accordion";
import classes from "./Faqs.module.css";
import faq from "./../../../img/Info.svg";
import ChatCircleDots from "./../../../img/ChatCircleDots.svg";
import WhatsappLogo from "./../../../img/WhatsappLogo.svg";
import CaretLeft from "./../../../img/CaretLeft.svg";
import { leftMenu } from "../ProfileMain";

const faqs = [
  {
    id: 1,
    header: "How can I reset my password?",
    text: `If you can't log in to your account, you should click the "Forgot Password" button on the login page.
If you are logged in and want to change your password, you can click the "Edit Profile" section on the menu.`,
  },
  {
    id: 2,
    header: "Are your Series and Movies with English subtitles?",
    text: `Yes, our all Turkish series and movies with English Subtitles. We don't provide any other languages. `,
  },
  {
    id: 3,
    header: "Do you have a trial period?",
    text: `Yes, you can click the "Try 2 New Episodes" button after you sign up. Then, you need to buy a subscription plan.`,
  },
  {
    id: 4,
    header: "How can I renew my subscription?",
    text: `You can go to "Subscription" section on the menu and choose your new plan.`,
  },
];

const Faqs = () => {
  const ctxContact = useContext(leftMenu);

  const showSidebar = () => {
    ctxContact();
  };
  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <div className={classes.faqsTop}>
      <div>
        <div className={classes.headerFaqs}>
          <img
            className={classes.caretLeft}
            onClick={showSidebar}
            src={CaretLeft}
            alt="CaretLeft"
          />
          <div className={classes.row}>
            <img src={faq} alt="faq" />
            <h1>FAQs</h1>
          </div>
          <p>Here are some of the most frequently asked questions</p>
        </div>
        {faqs.map((faq, index) => {
          return (
            <AccordionItem
              key={index}
              active={active}
              handleToggle={handleToggle}
              faq={faq}
            />
          );
        })}
      </div>

      <div className={classes.headerContent}>
        <div className={classes.contactInner}>
          <img
            className={classes.ChatCircleDots}
            src={ChatCircleDots}
            alt="ChatCircleDots"
          />
          <h1>Contact Us</h1>
        </div>
        <a href="https://wa.me/447312383516">
          <button>
            <span>
              <img src={WhatsappLogo} alt="WhatsappLogo" /> Contact in WhatsApp
            </span>
          </button>
        </a>
      </div>
      <textarea placeholder="Type your message here..." />
      <button className={classes.sendMessage}>Send message</button>
    </div>
  );
};

export default Faqs;
