import React from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import MoviesSlider from "../_MoviesSlider";

const Recommended = () => {
  const { data, isSuccess, isLoading } = useQuery({
    queryKey: ["recomended"],
    queryFn: () => {
      return axios.post(`${process.env.REACT_APP_BASE_URL}/api/recommendations?`, { userId: JSON.parse(localStorage.getItem("user"))?.body.user.user_id });
    },
  });

  return <MoviesSlider title={"Recommended"} data={data?.data.body.movies} isLoading={isLoading} />;
};

export default Recommended;
